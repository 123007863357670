import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import ConversionLogicTemplatesTable from './components/ConversionLogicTemplatesTable';
import useSorting from '../../hooks/useSorting';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import PageWithHeader from '../../components/header/PageWithHeader';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';

const ConversionLogicTemplatesPage = () => {
    const { notifyError } = useNotify();
    const { showModal } = useModals();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [data, loading, error, setData, reloadData] = useApi(
        'ConversionTemplates',
        'getConversionTemplates',
        {
            limit,
            offset: page * limit,
            sort,
            sortDir,
        }
    );

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <PageWithHeader
            title="Conversion Logic Templates"
            buttons={
                <ButtonWithIcon
                    title="Create new template"
                    onClick={() =>
                        showModal(MODAL_TYPE.ADD_LOGIC_TEMPLATE, {
                            title: `Create new template`,
                            content: 'Select a unique name that is not already in the system.',
                        })
                    }
                    icon={<AddIcon />}
                />
            }
        >
            <ConversionLogicTemplatesTable
                rows={data?.templates}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </PageWithHeader>
    );
};

export default ConversionLogicTemplatesPage;

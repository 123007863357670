import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';

const YearRangeSelector = ({ fromYear, setFromYear, toYear, setToYear }) => {
    const { availableYears } = useGlobalFilters();

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="year-select">From year</InputLabel>
                    <Select
                        labelId="year-select"
                        id="demo-simple-from-select"
                        value={fromYear}
                        size="small"
                        label="Year"
                        onChange={(e) => {
                            setFromYear(parseInt(e.target.value, 10));
                        }}
                    >
                        {availableYears.map((yearItem) => (
                            <MenuItem key={yearItem.year} value={yearItem.year}>
                                {yearItem.year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="to-year-select">To year</InputLabel>
                    <Select
                        labelId="to-year-select"
                        id="demo-simple-to-select"
                        value={toYear}
                        size="small"
                        label="Year"
                        onChange={(e) => {
                            setToYear(parseInt(e.target.value, 10));
                        }}
                    >
                        {availableYears.map((yearItem) => (
                            <MenuItem key={yearItem.year} value={yearItem.year}>
                                {yearItem.year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default YearRangeSelector;

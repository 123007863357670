import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS, SCENARIO_TYPES } from '../helpers/constans';
import { useAuth } from '../hooks/useAuth';
import GroupByComponent from './atom/GroupByComponent';
import { ESG_FEATURE_ENABLED } from '../config';

const ScenarioSelectSwitcher = ({ search, scenarioPage }) => {
    const navigate = useNavigate();
    const { userPermissions } = useAuth();

    const options = {
        GHG: 'GHG',
        ESG: 'ESG',
    };

    useEffect(() => {
        if (scenarioPage === SCENARIO_TYPES.GHG) {
            navigate('/scenarios');
        }
        if (scenarioPage === SCENARIO_TYPES.ESG) {
            navigate('/esgscenarios');
        }
    }, [scenarioPage]);

    if (!userPermissions.includes(PERMISSIONS.MANAGE_ESGS) || !ESG_FEATURE_ENABLED) {
        delete options.ESG;
    }

    return (
        <GroupByComponent
            label="Scenario type"
            groupBy={scenarioPage}
            setGroupBy={(value) => {
                return value === SCENARIO_TYPES.GHG
                    ? navigate('/scenarios')
                    : navigate('/esgscenarios');
            }}
            search={search}
            groupByOptions={options}
        />
    );
};

export default ScenarioSelectSwitcher;

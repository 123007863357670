import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import CompanyTagsTable from './components/CompanyTagsTable';
import useSorting from '../../hooks/useSorting';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import PageWithHeader from '../../components/header/PageWithHeader';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';

const CompanyTagsPage = () => {
    const navigate = useNavigate();
    const { notifyError } = useNotify();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [data, loading, error, setData, reloadData] = useApi('CompanyTags', 'getCompanyTags', {
        limit,
        pageSize: limit,
        page,
        sort,
        sortDir,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <PageWithHeader
            title="Company Tags"
            buttons={
                <ButtonWithIcon
                    title="Add new company tag"
                    onClick={() => navigate('/companytags/new')}
                    icon={<AddIcon />}
                />
            }
        >
            <CompanyTagsTable
                rows={data?.companyTags}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </PageWithHeader>
    );
};

export default CompanyTagsPage;

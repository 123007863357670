import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { doSwaggerCall } from '../../hooks/useApi';
import CommonGroup from '../../components/atom/CommonGroup';
import { SCENARIO_GROUPBY_OPTIONS, SCENARIO_GROUPS } from '../../helpers/groups';
import PageWithHeader from '../../components/header/PageWithHeader';
import SearchInput from '../../components/atom/SearchInput';
import GroupByComponent from '../../components/atom/GroupByComponent';
import { SCENARIO_TYPES } from '../../helpers/constans';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';
import ScenarioSelectSwitcher from '../../components/ScenarioSelectSwitcher';

const ScenariosPage = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [groupby, setGroupby] = useState(SCENARIO_GROUPS.NONE);
    const [groupOpen, setGroupOpen] = useState([0]);
    const [scenarioGroups, setScenarioGroups] = useState([]);

    // create a group props (target reduction, baseline year, target year, target scope)
    useEffect(() => {
        const baseline = {
            targetReduction: 50,
            baselineYear: 2022,
            targetYear: 2030,
            targetScopes: [],
            sectionHeader: '',
        };
        if (groupby === SCENARIO_GROUPS.NONE) {
            const sectionHeader = '';
            setScenarioGroups([
                {
                    id: 0,
                    name: 'All scenarios',
                    section: { ...baseline, sectionHeader },
                    type: 'all',
                },
            ]);
            setGroupOpen(['0-all']);
            return;
        }

        if (groupby === SCENARIO_GROUPS.TARGET) {
            doSwaggerCall('Scenarios', 'getScenarios', {
                search,
                // TODO: fix meeee
                limit: 1000,
                offset: 0,
            }).then((res) => {
                const transformResult = [];
                res.scenarios.forEach((scenario) => {
                    const sectionHeader = `${scenario.baseline_year} -> ${scenario.target_year} (${
                        scenario.target_scopes_header
                    }) ${scenario.site_names.length > 0 ? `- (${scenario.site_names})` : ''} ${
                        scenario.dataline_tag_name ? `- ${scenario.dataline_tag_name}` : ''
                    }`;
                    if (
                        !transformResult.map((t) => t.section.sectionHeader).includes(sectionHeader)
                    ) {
                        transformResult.push({
                            id: scenario.id,
                            name: scenario.name,
                            company_id: scenario.company_id,
                            baseline_year: scenario.baseline_year,
                            target_year: scenario.target_year,
                            target_reduction: scenario.target_reduction,
                            target_scopes: scenario.target_scopes,
                            target_scopes_hierarchy: scenario.target_scopes_hierarchy,
                            dataline_tag_id: scenario.dataline_tag_id,
                            site_filter: scenario.site_filter,
                            section: {
                                baselineYear: scenario.baseline_year,
                                targetYear: scenario.target_year,
                                targetScopes: scenario.target_scopes,
                                targetCategories: scenario.target_categories,
                                targetScopesHierarchy: scenario.target_scopes_hierarchy,
                                datalineTagId: scenario.dataline_tag_id,
                                siteFilter: scenario.site_filter,
                                sectionHeader,
                            },
                            type: 'scenario',
                        });
                    }
                });
                setScenarioGroups(transformResult);
                setGroupOpen([]);
            });
        }
    }, [groupby, setScenarioGroups]);

    return (
        <PageWithHeader
            title="GHG Targets and Plannings"
            searchInput={
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        height: '100%',
                    }}
                >
                    <SearchInput
                        fullWidth
                        search={search}
                        setSearch={setSearch}
                        groupby={groupby}
                        setGroupby={setGroupby}
                    />
                </Grid>
            }
            filters={[
                <GroupByComponent
                    groupBy={groupby}
                    setGroupBy={setGroupby}
                    groupByOptions={SCENARIO_GROUPBY_OPTIONS}
                    search={search}
                />,
                <ScenarioSelectSwitcher search={search} scenarioPage={SCENARIO_TYPES.GHG} />,
            ]}
            buttons={
                <ButtonWithIcon
                    title="Add Scenario"
                    onClick={() => navigate('/scenarios/new')}
                    icon={<AddIcon />}
                />
            }
        >
            <Grid item xs={12}>
                <Grid container spacing={0.5}>
                    {scenarioGroups.map((item, idx) => {
                        return (
                            <CommonGroup
                                key={idx}
                                groupby={groupby}
                                search={search}
                                setGroupOpen={setGroupOpen}
                                groupOpen={groupOpen}
                                item={item}
                                typeFlag="scenario"
                            />
                        );
                    })}
                </Grid>
            </Grid>
        </PageWithHeader>
    );
};

export default ScenariosPage;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import useNotify from '../../../hooks/useNotify';
import { TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constans';
import useSorting from '../../../hooks/useSorting';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import EsgHierarchyTable from '../components/EsgHierarchyTable';
import EsgEditorSwitcher from '../components/EsgEditorSwitcher';
import SearchInput from '../../../components/atom/SearchInput';
import PageWithHeader from '../../../components/header/PageWithHeader';
import ButtonWithIcon from '../../../components/header/ButtonWithIcon';

const EsgDisclosuresPage = () => {
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotify();
    const { esgCategoryId, esgSubcategoryId } = useParams();
    const [search, setSearch] = useState('');
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [data, loading, error, setData, reloadData] = useApi(
        'EsgDisclosures',
        'getEsgDisclosures',
        {
            esgSubcategoryId,
            search,
            limit,
            offset: page * limit,
            sort,
            sortDir,
        }
    );

    const deleteDisclosure = (id) => {
        doSwaggerCall('EsgDisclosures', 'deleteEsgDisclosure', { id })
            .then(() => {
                notifySuccess('ESG disclosure deleted');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <>
            <EsgEditorSwitcher />
            <PageWithHeader
                title="ESG Disclosures"
                searchInput={
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        setParams={setParams}
                        limit={limit}
                        fullWidth
                    />
                }
                buttons={[
                    <ButtonWithIcon
                        title="Back"
                        onClick={() => navigate(`/esgcategories/${esgCategoryId}/esgsubcategories`)}
                        icon={<ArrowBackIcon />}
                    />,

                    <ButtonWithIcon
                        onClick={() =>
                            navigate(
                                `/esgcategories/${esgCategoryId}/esgsubcategories/${esgSubcategoryId}/esgdisclosures/new`
                            )
                        }
                        label="Add new ESG disclosure"
                        icon={<AddIcon />}
                    />,
                ]}
            >
                <EsgHierarchyTable
                    title="ESG Disclosures"
                    rows={data?.esgDisclosures}
                    limit={limit}
                    page={page}
                    setParams={setParams}
                    total={data?.total}
                    loading={loading}
                    sort={sort}
                    sortDir={sortDir}
                    setSorting={setSorting}
                    deleteRow={deleteDisclosure}
                />
            </PageWithHeader>
        </>
    );
};

export default EsgDisclosuresPage;

import React from 'react';
import { ButtonGroup, Grid, Typography } from '@mui/material';

function PageWithHeader({ children, title, subtitle, searchInput, filters, buttons }) {
    const itemSize = {
        title: 12,
        subtitle: 6,
        search: 12,
        buttons: 2,
        filters: 12,
    };

    // title, subtitle, filters, (buttons)
    if (subtitle && !searchInput && filters) {
        itemSize.title -= itemSize.subtitle;
    }
    // title, buttons
    if (!subtitle && !searchInput && !filters && buttons) {
        itemSize.title -= itemSize.buttons;
    }
    // title, subtitle, search, filters, (buttons)
    if (subtitle && searchInput && filters) {
        itemSize.title -= itemSize.subtitle;
        itemSize.search -= filters.length * 3;
        itemSize.filters = 3;
    }
    // title, search, filters, (buttons)
    if (!subtitle && searchInput && filters) {
        itemSize.search -= filters.length * 3;
        itemSize.filters = 3;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={itemSize.title}>
                <Typography variant="h4">{title}</Typography>
            </Grid>
            {subtitle && (
                <Grid item xs={itemSize.subtitle} textAlign="right">
                    {subtitle}
                </Grid>
            )}
            {searchInput && (
                <Grid item xs={itemSize.search}>
                    {searchInput}
                </Grid>
            )}
            {filters?.map((item) => (
                <Grid item xs={itemSize.filters}>
                    {item}
                </Grid>
            ))}
            {buttons && (
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <Grid item xs={itemSize.buttons}>
                        {buttons.length === 1 ? (
                            buttons
                        ) : (
                            <ButtonGroup fullWidth>{buttons}</ButtonGroup>
                        )}
                    </Grid>
                </Grid>
            )}
            {children}
        </Grid>
    );
}

export default PageWithHeader;

import {
    Box,
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import DataArrayIcon from '@mui/icons-material/DataArray';
import Co2Icon from '@mui/icons-material/Co2';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import BarChartIcon from '@mui/icons-material/BarChart';
import CategoryIcon from '@mui/icons-material/Category';
import PlaceIcon from '@mui/icons-material/Place';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CalculateIcon from '@mui/icons-material/Calculate';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import StyleIcon from '@mui/icons-material/Style';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DifferenceIcon from '@mui/icons-material/Difference';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MessageIcon from '@mui/icons-material/Message';
import MailIcon from '@mui/icons-material/Mail';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useAuth } from '../../hooks/useAuth';
import { PERMISSIONS, ROLES } from '../../helpers/constans';
import { ESG_FEATURE_ENABLED, SUPPLIER_FEATURE_ENABLED } from '../../config';
import { navigateToDatalinesByPermission } from '../../helpers/navigateByPermission';

function SidebarMenuItem({ icon, title, submenuItems = [], onClick, navigate }) {
    const [submenuOpen, setSubmenuOpen] = useState(false);

    const handleClick = () => {
        if (submenuItems.length > 0) {
            setSubmenuOpen((prev) => !prev);
        } else if (onClick) {
            onClick();
        } else if (navigate) {
            navigate();
        }
    };

    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} />
                {submenuItems.length > 0 && (submenuOpen ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            {submenuItems.length > 0 && (
                <Collapse in={submenuOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {submenuItems.map((submenuItem, index) => (
                            <ListItemButton
                                key={index}
                                onClick={submenuItem.onClick || submenuItem.navigate}
                                sx={{ pl: 4 }}
                            >
                                <ListItemIcon>{submenuItem.icon}</ListItemIcon>
                                <ListItemText primary={submenuItem.title} />
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            )}
        </ListItem>
    );
}

export default function SidebarMenu() {
    const navigate = useNavigate();
    const { user, userPermissions } = useAuth();
    const dashboardAndReportPermission = userPermissions.includes(PERMISSIONS.VIEW_DASHBOARD);
    const datalinesPermission = userPermissions.includes(PERMISSIONS.VIEW_DATALINES);
    const assignedToPermission = userPermissions.includes(PERMISSIONS.VIEW_ASSIGNED_TO);
    const ownedPermission = userPermissions.includes(PERMISSIONS.VIEW_OWNED);
    const requirementsPermission = userPermissions.includes(PERMISSIONS.VIEW_REQUIREMENTS);
    const scenariosPermission = userPermissions.includes(PERMISSIONS.MANAGE_SCENARIOS);
    const companyPermission =
        userPermissions.includes(PERMISSIONS.MANAGE_COMPANY) && user.role === ROLES.USER;
    const companyPermissionOrSuperadmin = userPermissions.includes(PERMISSIONS.MANAGE_COMPANY);
    const suppliersPermission = userPermissions.includes(PERMISSIONS.MANAGE_SUPPLIERS);
    const esgsPermission = userPermissions.includes(PERMISSIONS.MANAGE_ESGS);

    const superadminSubmenuItems = [
        {
            title: 'Companies',
            icon: <BusinessIcon />,
            navigate: () => navigate('/companies'),
        },
        {
            title: 'Company Tags',
            icon: <StyleIcon />,
            navigate: () => navigate('/companytags'),
        },
        {
            title: 'Emission Sources',
            icon: <Co2Icon />,
            navigate: () => navigate('/emissionsources'),
        },
        {
            title: 'ESG Editor',
            icon: <CategoryOutlinedIcon />,
            navigate: () => navigate('/esgcategories'),
        },
        {
            title: 'Conversion Logic Templates',
            icon: <CalculateIcon />,
            navigate: () => navigate('/conversionlogictemplates'),
        },
        {
            title: 'Edit Categories',
            icon: <CategoryIcon />,
            navigate: () => navigate('/categories'),
        },
        {
            title: 'Edit Locations',
            icon: <PlaceIcon />,
            navigate: () => navigate('/locations/1'),
        },
        {
            title: 'Inventory Management Plan',
            icon: <InventoryIcon />,
            navigate: () => navigate(`/impsurveys`),
        },
        {
            title: 'Audit Logs',
            icon: <MessageIcon />,
            navigate: () => navigate('/auditlogs'),
        },
        {
            title: 'Email Logs',
            icon: <MailIcon />,
            navigate: () => navigate('/emaillogs'),
        },
    ];

    return (
        <List style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {user.role && (
                <>
                    {dashboardAndReportPermission && (
                        <SidebarMenuItem
                            title="Dashboard"
                            icon={<BarChartIcon />}
                            navigate={() => navigate('/dashboard')}
                        />
                    )}
                    {(datalinesPermission || assignedToPermission || ownedPermission) && (
                        <SidebarMenuItem
                            title="GHG Emissions"
                            icon={<DataArrayIcon />}
                            onClick={() =>
                                navigate(navigateToDatalinesByPermission(userPermissions))
                            }
                        />
                    )}
                    {esgsPermission && ESG_FEATURE_ENABLED && user.allowEsgForUsers && (
                        <SidebarMenuItem
                            title="Sustainability"
                            icon={<QuestionAnswerIcon />}
                            onClick={() => navigate('/esgs')}
                        />
                    )}
                    {requirementsPermission && (
                        <SidebarMenuItem
                            title="Progress Tracker"
                            icon={<AnnouncementIcon />}
                            navigate={() => navigate('/progresstracker')}
                        />
                    )}
                    {esgsPermission && ESG_FEATURE_ENABLED && user.allowEsgForUsers && (
                        <SidebarMenuItem
                            title="ESG Progress Tracker"
                            icon={<AnnouncementIcon />}
                            navigate={() => navigate('/esgprogresstracker')}
                        />
                    )}
                    {dashboardAndReportPermission && (
                        <SidebarMenuItem
                            title="Reporting"
                            icon={<FeedOutlinedIcon />}
                            navigate={() => navigate('/report')}
                        />
                    )}
                    {scenariosPermission && (
                        <SidebarMenuItem
                            title="Targets and Planning"
                            icon={<TrendingDownIcon />}
                            navigate={() => navigate('/scenarios')}
                        />
                    )}
                    {suppliersPermission && SUPPLIER_FEATURE_ENABLED && (
                        <SidebarMenuItem
                            title="Suppliers"
                            icon={<LocalShippingIcon />}
                            navigate={() => navigate('/suppliers')}
                        />
                    )}
                    {companyPermissionOrSuperadmin && (
                        <SidebarMenuItem
                            title="Variance Tracker"
                            icon={<DifferenceIcon />}
                            navigate={() =>
                                navigate(`/companies/${user.company_id}/variancetracker`)
                            }
                        />
                    )}
                    {companyPermission && (
                        <SidebarMenuItem
                            title="Company Admin"
                            icon={<BusinessIcon />}
                            submenuItems={[
                                {
                                    title: 'Company Details',
                                    icon: <BusinessIcon />,
                                    navigate: () =>
                                        navigate(`/companies/edit/${user.company_id}/basic`),
                                },
                                {
                                    title: 'Sites',
                                    icon: <HomeIcon />,
                                    navigate: () => navigate(`/companies/${user.company_id}/sites`),
                                },
                                {
                                    title: 'Users',
                                    icon: <GroupIcon />,
                                    navigate: () => navigate(`/companies/${user.company_id}/users`),
                                },
                                {
                                    title: 'IMP',
                                    icon: <InventoryIcon />,
                                    navigate: () => navigate(`/impsurveys`),
                                },
                            ]}
                        />
                    )}
                    {user.role === ROLES.SUPERADMIN && (
                        <SidebarMenuItem
                            title="Superadmin"
                            icon={<CategoryOutlinedIcon />}
                            submenuItems={superadminSubmenuItems}
                        />
                    )}
                    <Box style={{ flexGrow: 1 }} />
                    <SidebarMenuItem
                        title="Help"
                        icon={<HelpOutlineIcon />}
                        onClick={() => document.getElementById('zsiq_float').click()}
                    />
                </>
            )}
        </List>
    );
}

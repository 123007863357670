import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import getScopes from '../../dashboard/components/helpers/getScopes';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import CommonButton from '../../../components/atom/CommonButton';
import PageWithHeader from '../../../components/header/PageWithHeader';

function EditCategoryPage({ newCategory }) {
    const { categoryId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [category, setCategory] = useState(null);
    const { notifyError, notifySuccess } = useNotify();

    const defaultCategory = {
        name: 'New Category',
        scope: 1,
        guide_note: null,
    };

    const loadCategory = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Categories', 'getCategory', { id: categoryId })
            .then((res) => {
                setCategory({
                    id: res.id,
                    name: res.name,
                    scope: res.scope_id,
                    guide_note: res.guide_note,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate('/categories');
            });
    }, []);

    useEffect(() => {
        if (!newCategory) {
            loadCategory();
        } else {
            setLoading(false);
        }
    }, [categoryId]);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            if (newCategory) {
                await doSwaggerCall(
                    'Categories',
                    'addCategory',
                    {},
                    {
                        name: values.name.trim(),
                        scope: parseInt(values.scope, 10),
                        guideNote: values.guide_note,
                    }
                );
                navigate('/categories');
                notifySuccess('Category added');
            } else {
                await doSwaggerCall(
                    'Categories',
                    'editCategory',
                    { id: categoryId },
                    {
                        name: values.name.trim(),
                        scope: parseInt(values.scope, 10),
                        guideNote: values.guide_note,
                    }
                );
                loadCategory();
                setSubmitting(false);
                navigate('/categories');
                notifySuccess('Category updated');
            }
        } catch (err) {
            notifyError(err);
            setSubmitting(false);
        }
    };

    if (loading) {
        return true;
    }

    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
    });

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={category || { ...defaultCategory }}
            validationSchema={validateSchema}
        >
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <PageWithHeader title={newCategory ? 'Add new Category' : 'Edit Category'}>
                        <Grid item xs={6}>
                            <BlockWithTitle title="Category">
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        label="Name"
                                        value={values.name}
                                        onChange={(ev) => setFieldValue('name', ev.target.value)}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name ? errors.name : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Scope</InputLabel>
                                        <Select
                                            label="Scope"
                                            value={values.scope}
                                            onChange={(ev) =>
                                                setFieldValue('scope', ev.target.value)
                                            }
                                            error={touched.scope && Boolean(errors.scope)}
                                            helperText={touched.scope ? errors.scope : ''}
                                        >
                                            {getScopes()
                                                .sort((a, b) => a.order - b.order)
                                                .map((scope) => {
                                                    return (
                                                        <MenuItem value={scope.id}>
                                                            {scope.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Note for guidance (optional)"
                                        fullWidth
                                        autoComplete="off"
                                        multiline
                                        value={values?.guide_note}
                                        onChange={(ev) =>
                                            setFieldValue('guide_note', ev.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CommonButton
                                        fullWidth
                                        onClick={() => navigate(`/categories`)}
                                        label="Cancel"
                                        buttonType="secondary"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CommonButton
                                        fullWidth
                                        disabled={isSubmitting}
                                        type="submit"
                                        label="Save"
                                        buttonType="primary"
                                    />
                                </Grid>
                            </BlockWithTitle>
                        </Grid>
                    </PageWithHeader>
                </Form>
            )}
        </Formik>
    );
}

export default EditCategoryPage;

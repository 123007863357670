import { Grid, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import CommonButton from '../../../components/atom/CommonButton';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { esgFrameworkAutocomplete, esgQuestionAutocomplete } from '../../../autocomplete';
import PageWithHeader from '../../../components/header/PageWithHeader';

function EsgFrameworkQuestionEditForm({ onSubmit, title, initialValues, isNew, navigateBack }) {
    const navigate = useNavigate();

    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        rank: Yup.number().required('Rank is required'),
    });

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validateSchema}>
            {({ isSubmitting, values, touched, errors, setFieldValue }) => (
                <Form>
                    <PageWithHeader
                        title={isNew ? `Add new Framework question` : `Edit Framework question`}
                    >
                        <Grid item xs={6}>
                            <BlockWithTitle title={title}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        label="Name"
                                        value={values.name}
                                        onChange={(ev) => setFieldValue('name', ev.target.value)}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name ? errors.name : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete="off"
                                        fullWidth
                                        label="Hierarchy"
                                        value={values.hierarchy}
                                        onChange={(ev) =>
                                            setFieldValue('hierarchy', ev.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Rank"
                                        autoComplete="off"
                                        required
                                        value={values.rank}
                                        onChange={(ev) => setFieldValue('rank', ev.target.value)}
                                        error={touched.rank && Boolean(errors.rank)}
                                        helperText={touched.rank ? errors.rank : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutocompleteSelect
                                        initCall={esgQuestionAutocomplete.init}
                                        autocompleteCall={esgQuestionAutocomplete.search}
                                        value={values?.esg_question_id}
                                        setValue={(ev, getItem) => {
                                            const item = getItem(ev);
                                            setFieldValue('esg_question_id', item?.id || null);
                                        }}
                                    >
                                        <TextField
                                            label="ESG Question"
                                            fullWidth
                                            size="small"
                                            required
                                        />
                                    </AutocompleteSelect>
                                </Grid>
                                <Grid item xs={12}>
                                    <AutocompleteSelect
                                        initCall={esgFrameworkAutocomplete.init}
                                        autocompleteCall={esgFrameworkAutocomplete.search}
                                        // in case of new default is it's own framework
                                        defaultValue={initialValues?.esg_framework_id}
                                        value={values?.esg_framework_id}
                                        setValue={(ev, getItem) => {
                                            const item = getItem(ev);
                                            setFieldValue('esg_framework_id', item?.id || null);
                                        }}
                                    >
                                        <TextField
                                            label="ESG Framework"
                                            fullWidth
                                            size="small"
                                            required
                                        />
                                    </AutocompleteSelect>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Note for guidance (optional)"
                                        fullWidth
                                        multiline
                                        value={values?.guide_note}
                                        onChange={(ev) =>
                                            setFieldValue('guide_note', ev.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CommonButton
                                        fullWidth
                                        onClick={() => navigate(navigateBack)}
                                        label="Cancel"
                                        buttonType="secondary"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CommonButton
                                        fullWidth
                                        disabled={isSubmitting}
                                        type="submit"
                                        label="Save"
                                        buttonType="primary"
                                    />
                                </Grid>
                            </BlockWithTitle>
                        </Grid>
                    </PageWithHeader>
                </Form>
            )}
        </Formik>
    );
}

export default EsgFrameworkQuestionEditForm;

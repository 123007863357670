import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useNotify from '../../hooks/useNotify';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import useSorting from '../../hooks/useSorting';
import { useApi } from '../../hooks/useApi';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import BlankEsgsTable from './components/BlankEsgsTable';
import PageWithHeader from '../../components/header/PageWithHeader';

const EsgProgressTrackerPage = () => {
    const { notifyError } = useNotify();
    const { year, site } = useGlobalFilters();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });

    const [data, loading, error] = useApi('Esgs', 'getBlankEsgs', {
        year,
        sort,
        sortDir,
        limit,
        siteIdList: site,
        offset: page * limit,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <PageWithHeader title="ESG Progress Tracker">
            <Grid item xs={12}>
                <BlankEsgsTable
                    rows={data?.esgs}
                    total={data?.total}
                    limit={limit}
                    page={page}
                    sort={sort}
                    sortDir={sortDir}
                    setSorting={setSorting}
                    setParams={setParams}
                    loading={loading}
                />
            </Grid>
        </PageWithHeader>
    );
};

export default EsgProgressTrackerPage;

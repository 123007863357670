import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, Paper, Typography } from '@mui/material';
import { DASHBOARD_MENU_ITEMS } from '../../helpers/constans';

const displayIcon = (type) => {
    const menuItem = DASHBOARD_MENU_ITEMS.find((item) => item.value === type);
    return menuItem?.icon || null;
};

const PanelListItem = ({ provided, name, visual_type }) => {
    return (
        <Paper
            sx={{
                '&:not(:last-child)': {
                    mb: 1,
                },
                p: 1,
                borderWidth: 1,
            }}
            variant="outlined"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <Grid container spacing={0}>
                <Grid
                    item
                    container
                    xs={10}
                    justifyContent="flex-start"
                    spacing={0}
                    alignItems="center"
                >
                    {displayIcon(visual_type)}
                    <Typography variant="body2">&nbsp; {name}</Typography>
                </Grid>
                <Grid
                    item
                    container
                    xs={2}
                    spacing={0}
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <MoreVertIcon fontSize="small" />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default PanelListItem;

import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Grid, Paper, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/useAuth';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import useNotify from '../../hooks/useNotify';
import { doSwaggerCall } from '../../hooks/useApi';
import ScenarioSiteFilter from '../scenarios/components/targetpicker/components/ScenarioSiteFilter';
import CommonButton from '../../components/atom/CommonButton';
import recursiveChildrenForEsgScenario from './helpers/recursiveChildrenForEsgScenario';
import { cutEsgTree, transformData } from './helpers/targetPickerEsgHelperFunctions';
import BasicScenarioSettings from '../scenarios/components/BasicScenarioSettings';
import FilterComponent from '../../components/atom/FilterComponent';
import { groupQuestion } from '../../helpers/groups';
import PageWithHeader from '../../components/header/PageWithHeader';

function GrabAndCopyValue({ baselineYear, setBaselineYear }) {
    useEffect(() => {
        setBaselineYear(parseInt(baselineYear, 10));
    }, [setBaselineYear, baselineYear]);
    return null;
}

const validateSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    baseline_year: Yup.number().min(2000).required('Required'),
    target_year: Yup.number()
        .min(2000)
        .required('Required')
        .moreThan(Yup.ref('baseline_year'), 'Target year is not greater than baseline year!'),
});

function AddEsgScenarioPage() {
    const { user } = useAuth();
    const { availableYears } = useGlobalFilters();
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotify();
    const [selectedTargetData, setSelectedTargetData] = useState([]);
    const [siteFilter, setSiteFilter] = useState([]);
    const [esgTagId, setEsgTagId] = useState(0);
    const [baselineYear, setBaselineYear] = useState(
        availableYears.filter((e) => e.state === 'closed')[0]?.year || new Date().getFullYear() - 1
    );
    const [dataGroups, setDataGroups] = useState([]);

    const defaultScenario = {
        name: 'New ESG Scenario',
        company_id: user.company_id,
        baseline_year: baselineYear,
        target_year: new Date().getFullYear() + 9,
        esg_tag_id: 0,
        site_filter: [],
    };

    const onSubmit = async (values) => {
        try {
            // checkbox array validation
            if (selectedTargetData.length === 0) {
                notifyError('You should pick at least one target data!');
                return;
            }
            const res = await doSwaggerCall('EsgQuestions', 'getEsgQuestionHierarchy', {
                year: values.baseline_year,
                hasanyesgs: true,
                siteIdList: siteFilter,
                esgTagId: esgTagId !== 0 ? esgTagId : null,
            });
            const treeData = recursiveChildrenForEsgScenario({ children: res });
            if (treeData.children.length === 0) {
                notifyError(
                    'Please select an appropriate baseline year, which has data for the selected questions!'
                );
            } else {
                await doSwaggerCall(
                    'EsgScenarios',
                    'addEsgScenario',
                    {},
                    {
                        name: values.name,
                        baselineYear: parseInt(values.baseline_year, 10),
                        targetYear: parseInt(values.target_year, 10),
                        targetHierarchy: cutEsgTree(treeData.children, selectedTargetData),
                        siteFilter,
                        esgTagId: parseInt(esgTagId, 10),
                    }
                );
                navigate('/esgscenarios');
                notifySuccess('ESG Scenario added');
            }
        } catch (error) {
            notifyError(error);
        }
    };

    // custom autocomplete for ESG Questions
    const loadQuestions = useCallback((baselineY, siteIdList, tagId) => {
        doSwaggerCall('EsgQuestions', 'getEsgQuestionHierarchy', {
            year: baselineY,
            hasanyesgs: true, // show only data that have esgs
            siteIdList,
            esgTagId: tagId !== 0 ? tagId : null,
        })
            .then((hierarchy) => {
                setDataGroups(transformData(hierarchy));
            })
            .catch((error) => {
                notifyError(!baselineY ? 'Baseline year is required' : error);
            });
    }, []);

    useEffect(() => {
        loadQuestions(baselineYear, siteFilter, esgTagId);
        setSelectedTargetData([]); // reset selected data when changing the filter
    }, [baselineYear, siteFilter, esgTagId]);

    return (
        <PageWithHeader title="Add new ESG Scenario">
            <Formik
                onSubmit={onSubmit}
                initialValues={{ ...defaultScenario }}
                enableReinitialize
                validationSchema={validateSchema}
            >
                {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                    <Form>
                        <GrabAndCopyValue
                            baselineYear={values?.baseline_year}
                            setBaselineYear={setBaselineYear}
                        />
                        <Grid item xs={12} sx={{ pt: 2 }}>
                            <Paper sx={{ p: 2 }}>
                                <Grid container alignItems="center">
                                    <BasicScenarioSettings
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        touched={touched}
                                        availableYears={availableYears}
                                    />
                                    <Grid item xs={12}>
                                        <FilterComponent
                                            filterValue={esgTagId}
                                            setFilterValue={setEsgTagId}
                                            apiEndpoint="EsgTags"
                                            apiFunction="getAllEsgTags"
                                            itemName="esgTags"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ScenarioSiteFilter
                                            setSiteFilter={setSiteFilter}
                                            companyId={user.company_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            options={dataGroups}
                                            groupBy={(option) => groupQuestion(option)}
                                            value={selectedTargetData.map((value) =>
                                                dataGroups.find((e) => e.id === value.id)
                                            )}
                                            getOptionLabel={(option) => option?.name}
                                            onChange={(ev, newValue) => {
                                                setSelectedTargetData(
                                                    newValue.map((value) => ({
                                                        type: 'question',
                                                        id: value.id,
                                                    }))
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="ESG Questions" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CommonButton
                                            fullWidth
                                            onClick={() => navigate(`/esgscenarios`)}
                                            label="Cancel"
                                            buttonType="secondary"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CommonButton
                                            fullWidth
                                            disabled={
                                                isSubmitting || selectedTargetData.length === 0
                                            }
                                            type="submit"
                                            label="Save"
                                            buttonType="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </PageWithHeader>
    );
}

export default AddEsgScenarioPage;

import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { convertUnitGToKg } from '../../helpers/convertUnits';
import { exportToXlsx } from '../../helpers/excelExportHelpers';
import { doSwaggerCall, doSwaggerCallForPdf, useApi } from '../../hooks/useApi';
import ReportTable from './components/ReportTable';
import useNotify from '../../hooks/useNotify';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { BACKEND_URL } from '../../config';
import useSorting from '../../hooks/useSorting';
import { useModals, MODAL_TYPE } from '../../hooks/useModal';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import PageWithHeader from '../../components/header/PageWithHeader';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';

const ReportPage = () => {
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const [generatingAuditXlsx, setGeneratingAuditXlsx] = useState(false);
    const [progress, setProgress] = useState(0);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const { notifySuccess, notifyError, notifyInfo } = useNotify();
    const { year, site } = useGlobalFilters();
    const { showModal } = useModals();
    const [generatingPdf, setGeneratingPdf] = useState(false);

    const [data, loading, error] = useApi('Reports', 'getReportData', {
        year,
        pageSize: limit,
        page,
        sort,
        sortDir,
    });
    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    const loadDocumentsForDownload = async (structured = false) => {
        try {
            const { token } = await doSwaggerCall('Files', 'documentsRequest', {
                year,
                siteIdList: site,
                documentType: 'ghg',
            });
            const url = `${BACKEND_URL}/1.0/documents/fordownload?token=${token}&structured=${structured}`;
            window.open(url, '_blank', 'noopener,noreferrer');
        } catch (documentError) {
            notifyError(documentError);
        }
    };

    useEffect(() => {
        setParams({ page: 0, limit });
    }, [year, site]);

    /*
    const collectAllData = async () => {
        const allReportData = [];
        const localPageSize = 10;
        const totalReportData = data?.total;

        for (let i = 0; i < Math.ceil(totalReportData / localPageSize); i++) {
            const res = await doSwaggerCall('Reports', 'getReportData', {
                page: i,
                pageSize: localPageSize,
                year,
            });
            res.reportData.forEach((e) => allReportData.push(e));
            setProgress((prevProgress) => prevProgress + (localPageSize / totalReportData) * 100);
        }
        return allReportData.map((report) => ({
            scope: report.scope_name,
            category: report.category,
            subcategory: report.subcategory,
            emission_source: report.emission_source,
            siteName: report.siteName,
            sumOfCO2e: `${(convertUnitGToKg(report.sumOfCO2e) / 1000).toFixed(4)}`,
        }));
    };
    */

    const auditExport = async (
        tagId,
        siteIdList,
        scope,
        category,
        subcategory,
        emissionSource,
        selectedYear
    ) => {
        try {
            const reportRequest = await doSwaggerCall('Reports', 'auditReportRequest', {
                year: selectedYear,
            });
            let url = `${BACKEND_URL}/1.0/reports/auditdownload?token=${reportRequest.token}`;
            if (tagId && tagId !== 0) {
                url += `&reportByTag=${tagId}`;
            }
            if (scope) {
                url += `&scope=${scope}`;
            }
            if (category) {
                url += `&categoryId=${category}`;
            }
            if (subcategory) {
                url += `&subcategoryId=${subcategory}`;
            }
            if (emissionSource) {
                url += `&sourceId=${emissionSource}`;
            }
            if (siteIdList && siteIdList.length > 0) {
                const encodedSiteIdList = encodeURIComponent(siteIdList);
                url += `&siteList=${encodeURIComponent(encodedSiteIdList)}`;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
        } catch (err) {
            notifyError(err);
        }
    };

    const pdfExport = async (
        tagId,
        siteIdList,
        scope,
        category,
        subcategory,
        emissionSource,
        selectedYear
    ) => {
        setGeneratingPdf(true);
        let reportRequest = null;
        const params = {
            year: selectedYear,
        };
        if (tagId && tagId !== 0) {
            params.reportByTag = tagId;
        }
        if (scope) {
            params.scope = scope;
        }
        if (category) {
            params.categoryId = category;
        }
        if (subcategory) {
            params.subcategoryId = subcategory;
        }
        if (emissionSource) {
            params.sourceId = emissionSource;
        }
        if (siteIdList && siteIdList.length > 0) {
            params.siteIdList = siteIdList;
        }
        try {
            reportRequest = await doSwaggerCallForPdf('Reports', 'auditReportRequestPdf', {
                ...params,
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(reportRequest);
            link.download = `audit_report_${selectedYear}.pdf`;
            link.click();
        } catch (err) {
            notifyError(err);
            setGeneratingPdf(false);
        } finally {
            setGeneratingPdf(false);
        }
    };

    const auditReportXlsx = async (
        tagId,
        siteIdList,
        scope,
        category,
        subcategory,
        emissionSource,
        selectedYear
    ) => {
        const reportRequest = await doSwaggerCall('Reports', 'auditReportRequest', {
            year: selectedYear,
        });
        const params = {
            token: reportRequest.token,
        };
        if (tagId && tagId !== 0) {
            params.reportByTag = tagId;
        }
        if (scope) {
            params.scope = scope;
        }
        if (category) {
            params.categoryId = category;
        }
        if (subcategory) {
            params.subcategoryId = subcategory;
        }
        if (emissionSource) {
            params.sourceId = emissionSource;
        }
        if (siteIdList && siteIdList.length > 0) {
            params.siteIdList = siteIdList;
        }
        const reportData = await doSwaggerCall('Reports', 'getXlsxAuditReport', {
            ...params,
        });

        // field explosion
        return reportData.flatMap((report) => {
            const fields = report.fields.split(',').map((field) => field.trim());
            const fieldColumns = [];
            const fieldValues = [];
            fields.forEach((field, index) => {
                const [fieldUnit, fieldValue] = field.split(':').map((value) => value.trim());
                const fieldColumn = `Field ${index + 1}`;
                const fieldColumnUnit = `Field ${index + 1} unit`;
                fieldColumns.push(fieldColumn, fieldColumnUnit);
                fieldValues.push(fieldValue, fieldUnit);
            });

            return {
                scope: report.scope,
                category: report.category,
                subcategory: report.subcategory,
                emission_source: report.emission_source,
                site: report.site,
                co2e: `${(convertUnitGToKg(report.co2e) / 1000).toFixed(4)}`,
                emission_description: report.emission_description,
                documents: report.documents,
                notes: report.notes,
                usage: report.usage, // amount
                unit_of_measure: report.unit_of_measure, // base unit
                from_date: report.from_date,
                to_date: report.to_date,
                is_estimated: report.is_estimated,
                logs: report.logs,
                // fields
                ...Object.fromEntries(
                    fieldColumns.map((column, index) => [column, fieldValues[index]])
                ),
            };
        });
    };

    const xlsxConfirm = async (
        tagId,
        siteIdList,
        scope,
        category,
        subcategory,
        emissionSource,
        selectedYear
    ) => {
        setGeneratingAuditXlsx(true);
        let listToExport = [];
        try {
            listToExport = await auditReportXlsx(
                tagId,
                siteIdList,
                scope,
                category,
                subcategory,
                emissionSource,
                selectedYear
            );
        } catch (err) {
            notifyError(err);
        }
        if (listToExport.length === 0) {
            notifyInfo('No data for selected report!');
            setGeneratingAuditXlsx(false);
            return;
        }
        exportToXlsx(listToExport, `audit_report_${selectedYear}`, false, true);
        setGeneratingAuditXlsx(false);
        notifySuccess('Reports exported');
    };

    // different confirm function for audit report by reportType
    const confirmFunction = (
        reportTagId,
        siteIdList,
        reportType,
        scope,
        category,
        subcategory,
        emissionSource,
        selectedYear
    ) => {
        const commonParams = {
            reportTagId,
            siteIdList,
            scope,
            category,
            subcategory,
            emissionSource,
            selectedYear,
        };
        const handleExport = async (exportFunction) => {
            try {
                await exportFunction(...Object.values(commonParams));
            } catch (err) {
                notifyError(err);
            }
        };
        switch (reportType) {
            case 'XLSX':
                return handleExport(xlsxConfirm);
            case 'PDF':
                return handleExport(pdfExport);
            case 'HTML':
                return handleExport(auditExport);
            default:
                return () => {
                    notifyError('Something went wrong');
                };
        }
    };

    return (
        <PageWithHeader
            title="Report"
            buttons={[
                <ButtonWithIcon
                    title="Generate Audit Report"
                    onClick={() => {
                        showModal(MODAL_TYPE.SELECT_REPORT, {
                            confirm: (
                                reportTagId,
                                siteIdList,
                                reportType,
                                scope,
                                category,
                                subcategory,
                                emissionSource,
                                selectedYear
                            ) => {
                                confirmFunction(
                                    reportTagId,
                                    siteIdList,
                                    reportType,
                                    scope,
                                    category,
                                    subcategory,
                                    emissionSource,
                                    selectedYear
                                ).catch((err) => {
                                    notifyError(err);
                                });
                            },
                        });
                    }}
                    disabled={generatingAuditXlsx || generatingPdf}
                    icon={
                        generatingAuditXlsx || generatingPdf ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : (
                            <ArrowDropDownIcon />
                        )
                    }
                />,
                <ButtonWithIcon
                    title="Download all source data in a structured format"
                    onClick={() => {
                        loadDocumentsForDownload(true).catch((err) => {
                            notifyError(err);
                        });
                    }}
                    icon={<FolderZipIcon />}
                />,
            ]}
        >
            {/* 2023-08-03 call: remove aggregated data export
                          <Grid item>
                            <Button
                                fullWidth
                                onClick={async () => {
                                    setGeneratingXlsx(true);

                                    let listToExport = [];
                                    try {
                                        listToExport = await collectAllData();
                                    } catch (err) {
                                        notifyError(err);
                                    }
                                    exportToXlsx(listToExport, `simple_table_report_${year}`);
                                    setGeneratingXlsx(false);
                                    notifySuccess('Reports exported');
                                    setProgress(0);
                                }}
                            >
                                {generatingXlsx ? (
                                    <CircularProgress
                                        variant="determinate"
                                        color="inherit"
                                        size={25}
                                        value={progress}
                                    />
                                ) : (
                                    'Generate report to XLSX'
                                )}
                            </Button>
                        </Grid> */}
            <ReportTable
                rows={data?.reportData}
                loading={loading}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </PageWithHeader>
    );
};

export default ReportPage;

import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Grid, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { doSwaggerCall, doSwaggerCallForPdf } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import formatErrorToString from '../../helpers/formatErrorToString';
import SurveyHeader from '../survey/components/SurveyHeader';
import ErrorComponent from '../survey/components/ErrorComponent';
import jsonConstants from '../../helpers/impFormQuestions';
import { ImpSurveyStepContextProvider } from './components/ImpSurveyStep';
import ImpSurveyStepper from './components/ImpSurveyStepper';
import QQuestionList from './components/QQuestionList';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { useAuth } from '../../hooks/useAuth';
import ImpSurveyFooter from './components/ImpSurveyFooter';
import AutoSaveFormik from '../../components/atom/AutoSaveFormik';
import { BACKEND_URL } from '../../config';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';

// 8 steps
const IMP_SURVEY_STEPS = jsonConstants.map((question) => question.name);

const DEFAULT_SURVEY = {
    answers: {},
};
jsonConstants.forEach((section, sectionIndex) => {
    section.questions.forEach((question, questionIndex) => {
        const formikKey = `answer_${sectionIndex}_${questionIndex}`;
        if (question.answers) {
            DEFAULT_SURVEY.answers[formikKey] = question.answers[0];
        } else if (question.ghg) {
            DEFAULT_SURVEY.answers[formikKey] = {
                fields: [
                    {
                        name: '',
                        ghg: Array(7).fill(0),
                    },
                ],
            };
        } else if (question.default) {
            DEFAULT_SURVEY.answers[formikKey] = question.default;
        } else if (sectionIndex === 2 && questionIndex === 2) {
            DEFAULT_SURVEY.answers[formikKey] = {
                fields: [
                    {
                        facilityName: '',
                        ownershipPercentage: '',
                        ownershipStatus: '',
                    },
                ],
                others: '',
            };
        } else if (sectionIndex === 3 && questionIndex === 0) {
            DEFAULT_SURVEY.answers[formikKey] = '';
        } else if (sectionIndex === 6 && questionIndex === 0) {
            DEFAULT_SURVEY.answers[formikKey] = {
                fields: [
                    {
                        name: '',
                        responsibities: '',
                    },
                ],
                others: '',
            };
        } else {
            DEFAULT_SURVEY.answers[formikKey] = '';
        }
    });
});

const ImpSurveyPage = () => {
    const { user } = useAuth();
    const { year } = useGlobalFilters();
    const { notifyError, notifySuccess, notifyInfo } = useNotify();
    const [loading, setLoading] = useState(true);
    const [impSurvey, setImpSurvey] = useState();
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const [loadingError, setLoadingError] = useState(false);
    const [categories, setCategories] = useState([]);
    const [isComplete, setIsComplete] = useState(false);
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const { showModal } = useModals();

    const onSubmit = async (values) => {
        try {
            await doSwaggerCall(
                'ImpForms',
                'editImpForm',
                {
                    id: values.id,
                },
                {
                    answers: values.answers,
                    status: values.status,
                }
            );
        } catch (err) {
            notifyError(err);
        }
    };

    const saveStep = async (values, complete, isNext) => {
        try {
            await doSwaggerCall(
                'ImpForms',
                'editImpForm',
                {
                    id: values.id,
                },
                {
                    answers: values.answers,
                    status: isNext ? values.status : !!complete,
                }
            );
            if (!isNext) {
                notifySuccess(
                    `Inventory management plan saved ${complete ? 'and completed' : 'as a draft'}!`
                );
                if (complete) {
                    navigate(`/companies/edit/${impSurvey.companyId}/basic`);
                }
            } else {
                setStep(step + 1);
            }
        } catch (err) {
            notifyError(err);
        }
    };

    const loadImpForm = useCallback(async () => {
        setLoadingError(false);
        setLoading(true);
        try {
            const impFormRes = await doSwaggerCall('ImpForms', 'getImpForm', {
                year,
                companyId: user.company_id,
            });
            const categoriesRes = await doSwaggerCall('Categories', 'getAllCategories', {
                page: 0,
                limit: 1000,
            });
            // Handle categories response here
            setCategories(
                categoriesRes.categories
                    .filter((c) => c.scope_id === 1 || c.scope_id === 2 || c.scope_id === 3)
                    .map((c) => ({ name: c.name, scope_id: c.scope_id }))
            );
            setImpSurvey({
                id: impFormRes.id,
                year: impFormRes.year,
                answers: {
                    // just to fill in the default data
                    ...DEFAULT_SURVEY.answers,
                    ...impFormRes.answers,
                },
                uploadedBy: impFormRes.upoaded_by,
                status: impFormRes.status,
                companyName: impFormRes.company_name,
                companyId: impFormRes.company_id,
            });
            setIsComplete(impFormRes.status);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            notifyError(err);
            setLoadingError(formatErrorToString(err));
        }
    }, [year, user.company_id, notifyError]);

    useEffect(() => {
        if (year) {
            loadImpForm();
        } else {
            setLoading(false);
        }
    }, [year]);

    const onNext = useCallback(() => {
        setStep((old) => old + 1);
    }, [setStep]);
    const onPrev = useCallback(() => {
        setStep((old) => old - 1);
    }, [setStep]);
    const maxStep = IMP_SURVEY_STEPS.length;

    if (loading) {
        return true;
    }

    const openImpDocument = async (excludeBlankSections) => {
        try {
            const impDocument = await doSwaggerCall('ImpForms', 'getImpForm', {
                year,
                companyId: user.company_id,
            });
            // if status is true, and url is null, generate pdf
            if (impDocument.status && !impDocument.url) {
                setGeneratingPdf(true);
                let reportRequest = null;
                reportRequest = await doSwaggerCallForPdf('Reports', 'impReportRequestPdf', {
                    year,
                    impFormId: impSurvey.id,
                    excludeBlankSections,
                });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(reportRequest);
                link.download = `imp_report_${year}.pdf`;
                link.click();
            } else if (impDocument.url) {
                const impDocumentUrl = `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(
                    impDocument.url
                )}`;
                window.open(impDocumentUrl, '_blank');
            } else {
                notifyInfo('No inventory management plan form uploaded');
            }
        } catch (err) {
            notifyError(err);
            setGeneratingPdf(false);
        } finally {
            setGeneratingPdf(false);
        }
    };

    return (
        <Grid container alignContent="flex-start" alignItems="center">
            {isComplete && (
                <Alert severity="success">
                    <Grid item xs={12}>
                        Inventory Management Plan is already completed!
                    </Grid>
                    <Typography variant="subtitle1" align="center">
                        <Button
                            variant="text"
                            fullWidth
                            disabled={generatingPdf}
                            onClick={() =>
                                showModal(MODAL_TYPE.CONFIRMATION_IMP_DOWNLOAD, {
                                    title: 'Download IMP Form',
                                    content: 'Please select the options below.',
                                    confirm: (excludeBlankSections) =>
                                        openImpDocument(excludeBlankSections),
                                })
                            }
                        >
                            {generatingPdf
                                ? 'Generating PDF...'
                                : 'Click here to download the form'}
                        </Button>
                    </Typography>
                </Alert>
            )}
            <Grid item xs={12} md={12}>
                {loadingError && <ErrorComponent error={loadingError} />}
                {!loadingError && (
                    <Formik initialValues={impSurvey || { ...DEFAULT_SURVEY }} onSubmit={onSubmit}>
                        {({ values }) => (
                            <Form>
                                <AutoSaveFormik debounceMs={6000} />
                                <ImpSurveyStepContextProvider setStep={setStep} step={step}>
                                    <SurveyHeader
                                        companyName={impSurvey.companyName}
                                        year={impSurvey.year}
                                        impFlag
                                    />
                                    <Grid container>
                                        <ImpSurveyStepper
                                            IMP_SURVEY_STEPS={IMP_SURVEY_STEPS}
                                            step={step}
                                            setStep={setStep}
                                        />
                                        <Grid item xs={12} md={9}>
                                            <Grid container spacing={2}>
                                                {jsonConstants.map(
                                                    ({ questions }, sectionIndex) => {
                                                        return (
                                                            <QQuestionList
                                                                jsonConstants={jsonConstants}
                                                                sectionIndex={sectionIndex}
                                                                questions={questions}
                                                                categories={categories}
                                                            />
                                                        );
                                                    }
                                                )}
                                                <ImpSurveyFooter
                                                    onPrev={step > 1 ? onPrev : false}
                                                    onNext={step < maxStep ? onNext : false}
                                                    saveStep={saveStep}
                                                    values={values}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ImpSurveyStepContextProvider>
                            </Form>
                        )}
                    </Formik>
                )}
            </Grid>
        </Grid>
    );
};
export default ImpSurveyPage;

import { Button, Tooltip } from '@mui/material';
import React from 'react';

const ButtonWithIcon = ({ title, onClick, icon, ...props }) => {
    return (
        <Tooltip title={title}>
            <Button fullWidth onClick={onClick} variant="contained" {...props}>
                {icon}
            </Button>
        </Tooltip>
    );
};

export default ButtonWithIcon;

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BlankDatalinesTable from './components/BlankDatalinesTable';
import useNotify from '../../hooks/useNotify';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import useSorting from '../../hooks/useSorting';
import { useApi } from '../../hooks/useApi';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import ClosedYearAlert from '../../components/atom/ClosedYearAlert';
import PageWithHeader from '../../components/header/PageWithHeader';

const RequirementsPage = () => {
    const { notifyError } = useNotify();
    const { year, site, closedYear } = useGlobalFilters();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });

    const [data, loading, error, setData, reloadData] = useApi('Datalines', 'getBlankDatalines', {
        year,
        sort,
        sortDir,
        limit,
        siteIdList: site,
        offset: page * limit,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <>
            {closedYear && (
                <Grid item xs={12} sx={{ mb: 1 }}>
                    <ClosedYearAlert />
                </Grid>
            )}
            <PageWithHeader title="Progress tracker">
                <Grid item xs={12}>
                    <BlankDatalinesTable
                        rows={data?.datalines}
                        total={data?.total}
                        limit={limit}
                        page={page}
                        sort={sort}
                        sortDir={sortDir}
                        setSorting={setSorting}
                        setParams={setParams}
                        loading={loading}
                        reloadData={reloadData}
                    />
                </Grid>
            </PageWithHeader>
        </>
    );
};

export default RequirementsPage;

import React, { useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import CommonButton from '../components/atom/CommonButton';

const ConfirmationImpDownloadModal = ({ title, content, confirm, showModal, MODAL_TYPE }) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [excludeBlankSections, setExcludeBlankSections] = useState(false);

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={excludeBlankSections}
                                onChange={(e) => setExcludeBlankSections(!!e.target.checked)}
                            />
                        }
                        label="Exclude blank sections"
                        labelPlacement="end"
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm(excludeBlankSections);
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Download"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationImpDownloadModal;

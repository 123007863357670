import { Grid, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import CommonButton from '../../../components/atom/CommonButton';
import PageWithHeader from '../../../components/header/PageWithHeader';

function EsgEditForm({ onSubmit, title, initialValues, navigateBack, isNew }) {
    const navigate = useNavigate();

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <PageWithHeader title={isNew ? `Add new ${title}` : `Edit ${title}`}>
                        <Grid item xs={6}>
                            <BlockWithTitle title={title}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        label="Name"
                                        value={values.name}
                                        required
                                        onChange={(ev) => setFieldValue('name', ev.target.value)}
                                    />
                                </Grid>
                                {title !== 'ESG Framework' && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            autoComplete="off"
                                            type="number"
                                            label="Rank"
                                            required
                                            value={values.rank}
                                            onChange={(ev) =>
                                                setFieldValue('rank', ev.target.value)
                                            }
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={6}>
                                    <CommonButton
                                        fullWidth
                                        onClick={() => navigate(navigateBack)}
                                        label="Cancel"
                                        buttonType="secondary"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CommonButton
                                        fullWidth
                                        disabled={isSubmitting}
                                        type="submit"
                                        label="Save"
                                        buttonType="primary"
                                    />
                                </Grid>
                            </BlockWithTitle>
                        </Grid>
                    </PageWithHeader>
                </Form>
            )}
        </Formik>
    );
}

export default EsgEditForm;

import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TotalEmissionsPaper from './components/TotalEmissionsPaper';
import ByCategory from './components/ByCategory';
import DataCompletion from './components/DataCompletion';
import SubcategoryBreakdown from './components/SubcategoryBreakdown';
import { useAuth } from '../../hooks/useAuth';
import { PERMISSIONS } from '../../helpers/constans';
import { doSwaggerCall } from '../../hooks/useApi';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import useNotify from '../../hooks/useNotify';
import PageWithHeader from '../../components/header/PageWithHeader';
import CommonButton from '../../components/atom/CommonButton';
import FilterComponent from '../../components/atom/FilterComponent';

const Dashboard = () => {
    const { year, site } = useGlobalFilters();
    const [tagFilter, setTagFilter] = useState(0);
    const { userPermissions } = useAuth();
    const requirementsPermission = userPermissions.includes(PERMISSIONS.VIEW_REQUIREMENTS);
    const [loading, setLoading] = useState(true);
    const [yearSumData, setYearSumData] = useState({});
    const { notifyError } = useNotify();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        doSwaggerCall('Hierarchy', 'getSourceHierarchySum', {
            year,
            siteIdList: site,
            reportByTag: tagFilter !== 0 ? tagFilter : null,
        })
            .then((res) => {
                setYearSumData(res);
            })
            .catch((error) => {
                notifyError(error);
            })
            .then(() => {
                setLoading(false);
            });
    }, [year, site, setLoading, tagFilter, setYearSumData]);

    return (
        <PageWithHeader
            title="Dashboard"
            subtitle={<Typography variant="caption">All values are in CO2e (mt)</Typography>}
            buttons={
                <CommonButton
                    fullWidth
                    onClick={() => navigate('/dashboardtemplates')}
                    label="Custom dashboards"
                    buttonType="contained"
                />
            }
            filters={[
                <FilterComponent
                    filterValue={tagFilter}
                    setFilterValue={setTagFilter}
                    apiEndpoint="DatalineTags"
                    apiFunction="getAllDatalineTags"
                    itemName="datalineTags"
                />,
            ]}
        >
            {loading && (
                <Grid item xs={12}>
                    <LinearProgress sx={{ mt: 0.25 }} />
                </Grid>
            )}
            {!loading && (
                <>
                    <Grid item xs={12}>
                        <TotalEmissionsPaper tagFilter={tagFilter} yearSumData={yearSumData} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2 }}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">By category</Typography>
                            </Grid>
                            <ByCategory tagFilter={tagFilter} yearSumData={yearSumData} />
                        </Paper>
                    </Grid>
                    {requirementsPermission && false && (
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2 }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">Data completion</Typography>
                                    </Grid>
                                    <DataCompletion tagFilter={tagFilter} />
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2 }}>
                            <Grid container spacing={2}>
                                <SubcategoryBreakdown
                                    tagFilter={tagFilter}
                                    yearSumData={yearSumData}
                                    fromOldDashboard
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                </>
            )}
        </PageWithHeader>
    );
};

export default Dashboard;

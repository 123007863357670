import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import useNotify from '../../hooks/useNotify';
import useSorting from '../../hooks/useSorting';
import { TABLE_ROW_COUNT_DEFAULT, SCENARIO_TYPES } from '../../helpers/constans';
import { useApi } from '../../hooks/useApi';
import EsgScenariosTable from './components/EsgScenariosTable';
import SearchInput from '../../components/atom/SearchInput';
import PageWithHeader from '../../components/header/PageWithHeader';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';
import ScenarioSelectSwitcher from '../../components/ScenarioSelectSwitcher';

const EsgScenariosPage = () => {
    const navigate = useNavigate();
    const { notifyError } = useNotify();
    const [search, setSearch] = useState('');
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [data, loading, error, setData, reloadData] = useApi('EsgScenarios', 'getEsgScenarios', {
        search,
        limit,
        offset: page * limit,
        sort,
        sortDir,
    });
    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <PageWithHeader
            title="ESG Targets and Plannings"
            buttons={
                <ButtonWithIcon
                    title="Add Scenario"
                    onClick={() => navigate('/esgscenarios/new')}
                    icon={<AddIcon />}
                />
            }
            searchInput={
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        height: '100%',
                    }}
                >
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        setParams={setParams}
                        limit={limit}
                        fullWidth
                    />
                </Grid>
            }
            filters={[<ScenarioSelectSwitcher search={search} scenarioPage={SCENARIO_TYPES.ESG} />]}
        >
            <EsgScenariosTable
                rows={data?.scenarios}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </PageWithHeader>
    );
};

export default EsgScenariosPage;

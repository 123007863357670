import React, { useState } from 'react';
import {
    Grid,
    TextField,
    InputAdornment,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Checkbox,
} from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
    filterJsonConstantsByMeasure,
    unitConverter,
    WITHOUT_UNIT,
} from '../../../helpers/esgUnitConversions';
import FloatInput from '../../../components/atom/FloatInput';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import InfoIconComponent from '../../../components/atom/InfoIconComponent';

// check if the measure is visible, type is default to number since there is a case where we do not need this check
function isMeasureVisible(measure, defaultMeasure, type = 'number', forceOverride = false) {
    // calculated value, always visible (CO2e or base unit)
    if (forceOverride) {
        return true;
    }
    if (type === 'text' || type === 'select') {
        return false;
    }
    if (measure && measure === WITHOUT_UNIT) {
        return false;
    }
    if (!measure && defaultMeasure === WITHOUT_UNIT) {
        return false;
    }
    return true;
}

const EsgQuestionField = ({
    id,
    label,
    type,
    defaultMeasure,
    idx,
    values,
    setFieldValue,
    closedYear = false,
    isScenario = false,
    questionName = '',
    guideNote = '',
    ghg = [],
    use = null,
}) => {
    const [suggesting, setSuggesting] = useState(false);
    const [showForceOverrideHelperIcon, setShowForceOverrideHelperIcon] = useState(
        values.values[idx]?.forceOverride || false
    );
    const { notifyError } = useNotify();

    async function suggestingValue(value) {
        try {
            setSuggesting(true);
            const response = await doSwaggerCall(
                'Esgs',
                'getEsgSuggestion',
                {},
                {
                    question: value.esg_question?.name || questionName,
                    field:
                        value?.esg_question?.fields?.find((field) => field.id === id)?.label ||
                        label,
                    guidance: value?.esg_question?.guide_note || guideNote,
                }
            );
            setFieldValue(`values[${idx}]`, {
                id,
                value: response.suggestion,
                measure: values.values[idx]?.measure || defaultMeasure,
                forceOverride: values.values[idx]?.forceOverride,
            });
        } catch (err) {
            notifyError(err);
        } finally {
            setSuggesting(false);
        }
    }

    // check if the startAdornment should be visible (isScenario or ghg.length === 0)
    const startAdornmentCondition = () => {
        if (isScenario) {
            return !values.values[idx]?.forceOverride ? null : true;
        }
        return ghg.length === 0 ? null : true;
    };

    // get the measure value based on the use value and the measure value
    const getMeasureValue = () => {
        const measure = values.values[idx]?.measure || defaultMeasure;
        const useValue = values.values[idx]?.use || use;
        if (useValue === 'CO2e') {
            return 'CO2e';
        }
        if (useValue === 'Base unit') {
            // use emission source base unit instead of default measure of the field (or backup default measure)
            return values.values[idx]?.baseUnit || defaultMeasure;
        }
        return measure;
    };

    const commonInputProps = {
        startAdornment: startAdornmentCondition() && (
            <>
                <Tooltip
                    title="Force override possibility: this value is connected to a GHG, if you want to override the calculated data, uncheck this box"
                    placement="top"
                >
                    <Checkbox
                        disabled={closedYear || isScenario}
                        checked={!!values.values[idx]?.forceOverride}
                        onChange={(e) => {
                            setFieldValue(`values[${idx}]`, {
                                id,
                                // if the checkbox is checked, we will generate/calculate, so set the value to 0 to avoid confusion
                                value: 0,
                                measure: values.values[idx]?.measure || defaultMeasure,
                                forceOverride: e.target.checked,
                            });
                            setShowForceOverrideHelperIcon(e.target.checked === true);
                        }}
                    />
                </Tooltip>
                <InfoIconComponent showForceOverride={showForceOverrideHelperIcon} />
            </>
        ),
        endAdornment: isMeasureVisible(
            values.values[idx]?.measure,
            defaultMeasure,
            type,
            values.values[idx]?.forceOverride
        ) && (
            <Grid item>
                {!isMeasureVisible(
                    values.values[idx]?.measure,
                    defaultMeasure,
                    type,
                    values.values[idx]?.forceOverride
                ) || showForceOverrideHelperIcon ? (
                    <Tooltip title="This unit is not editable" placement="top">
                        <InputAdornment position="end">{getMeasureValue()}</InputAdornment>
                    </Tooltip>
                ) : (
                    <FormControl size="small" fullWidth>
                        <InputLabel id="measure-select" />
                        <Select
                            disabled={
                                isScenario ||
                                closedYear ||
                                values.values[idx]?.forceOverride === true
                            }
                            labelId="measure-select"
                            className="question-field"
                            value={values.values[idx]?.measure || defaultMeasure}
                            size="small"
                            onChange={(e) => {
                                setFieldValue(`values[${idx}]`, {
                                    id,
                                    value: unitConverter(
                                        values.values[idx]?.value,
                                        values.values[idx]?.measure || defaultMeasure,
                                        e.target.value
                                    ),
                                    measure: e.target.value,
                                    forceOverride: values.values[idx]?.forceOverride,
                                });
                            }}
                        >
                            {filterJsonConstantsByMeasure(
                                values.values[idx]?.measure || defaultMeasure
                            ).map((item) => (
                                <MenuItem key={item.name} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Grid>
        ),
    };

    return (
        <Grid item xs={12} key={id}>
            {type === 'number' && (
                <FloatInput
                    fullWidth
                    autoComplete="off"
                    label={label}
                    type="number"
                    value={values.values[idx]?.value || ''}
                    onChange={(ev) => {
                        setFieldValue(`values[${idx}]`, {
                            id,
                            value: ev.target.rawValue,
                            measure: values.values[idx]?.measure || defaultMeasure,
                            forceOverride: values.values[idx]?.forceOverride,
                        });
                        setShowForceOverrideHelperIcon(values.values[idx]?.forceOverride);
                    }}
                    InputProps={commonInputProps}
                    disabled={
                        isScenario || closedYear || values.values[idx]?.forceOverride === true
                    }
                    sx={{
                        '& .MuiInputLabel-root': {
                            fontSize: '1.5rem',
                            fontWeight: '700',
                        },
                    }}
                />
            )}
            {type === 'text' && (
                <TextField
                    disabled={isScenario || closedYear}
                    multiline
                    fullWidth
                    autoComplete="off"
                    label={label}
                    value={values.values[idx]?.value || ''}
                    onChange={(ev) => {
                        setFieldValue(`values[${idx}]`, {
                            id,
                            value: ev.target.value,
                            measure: values.values[idx]?.measure || defaultMeasure,
                            forceOverride: values.values[idx]?.forceOverride,
                        });
                    }}
                    InputLabelProps={{
                        shrink: true,
                        sx: { fontSize: '1.5rem', fontWeight: '700' },
                    }}
                    InputProps={{
                        ...commonInputProps,
                        // AI suggestion part
                        endAdornment: (
                            <InputAdornment position="end">
                                {!isScenario && (
                                    <IconButton
                                        sx={{ p: 0 }}
                                        disabled={closedYear}
                                        onClick={() => suggestingValue(values)}
                                        disableRipple
                                    >
                                        {suggesting ? '...' : <AutoFixHighIcon />}
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {type === 'select' && (
                <FormControl size="small" fullWidth>
                    <InputLabel id="answer-select" sx={{ mt: 1 }}>
                        {label}
                    </InputLabel>
                    <Select
                        label={label}
                        labelId="answer-select"
                        value={values.values[idx]?.value || ''}
                        onChange={(ev) => {
                            setFieldValue(`values[${idx}]`, {
                                id,
                                value: ev.target.value,
                                measure: values.values[idx]?.measure || defaultMeasure,
                                forceOverride: values.values[idx]?.forceOverride,
                            });
                        }}
                        disabled={isScenario || closedYear}
                        InputProps={commonInputProps}
                    >
                        <MenuItem value="">Not answered yet</MenuItem>
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                    </Select>
                </FormControl>
            )}
        </Grid>
    );
};

export default EsgQuestionField;

import React, { useEffect, useState } from 'react';
import { ButtonGroup, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useNavigate } from 'react-router-dom';
import useSorting from '../../../hooks/useSorting';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import useNotify from '../../../hooks/useNotify';
import SuppliersTable from './SuppliersTable';
import { parseXlsxSheets, runImport } from '../../../helpers/excelImportSuppliersHelpers';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { exportToXlsx, getSuppliersForOneCompany } from '../../../helpers/excelExportHelpers';
import { TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constans';
import InputButton from '../../../components/atom/InputButton';
import ButtonWithIcon from '../../../components/header/ButtonWithIcon';

export default function SupplierSection({
    search,
    sectionHeader,
    groupOpen,
    item,
    open,
    setOpen,
    groupby,
    setSuppliersChanged,
}) {
    const { notifyError, notifySuccess } = useNotify();
    const { year } = useGlobalFilters();
    const navigate = useNavigate();
    const [selectMode, setSelectMode] = useState(false);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [importingXlsx, setImportingXlsx] = useState(false);
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const { showModal } = useModals();

    useEffect(() => {
        setParams({ page: 0, limit });
    }, [year, search]);

    let getSuppliersParams = {
        search,
        limit,
        offset: page * limit,
        sort,
        sortDir,
        year,
    };
    if (groupby === 'INDUSTRY') {
        getSuppliersParams = {
            ...getSuppliersParams,
            industry: item.industry,
        };
    }
    if (groupby === 'LOCATION') {
        getSuppliersParams = {
            ...getSuppliersParams,
            locationId: item.locationId,
        };
    }

    const [data, loading, error, setData, reloadData] = useApi('Suppliers', 'getSuppliers', {
        ...getSuppliersParams,
    });

    const sampleData = [
        {
            id: '',
            name: 'Example Name',
            industry: 'Example Industry',
            location: 'Budapest',
            scope: '',
            category: '',
            marked_for_deletion: '',
            contact_name1: 'Example Name',
            contact_email1: 'example@gmail.com',
            contact_phone1: '123456789',
            contact_name2: 'Example Name2',
            contact_email2: 'example2@gmail.com',
            contact_phone2: '',
            contact_name3: 'Example Name3',
            contact_email3: 'example3@gmail.com',
            contact_phone3: '',
        },
    ];

    const getContactsForExport = (suppliers) => {
        const resultSupplier = [];
        suppliers.forEach((supplier) => {
            let resultContacts = {};
            supplier.contacts.forEach((contact, idx) => {
                resultContacts = {
                    ...resultContacts,
                    [`contact_name${idx + 1}`]: contact.name,
                    [`contact_email${idx + 1}`]: contact.email,
                    [`contact_phone${idx + 1}`]: contact.phone,
                };
            });

            // need to store contacts in one object
            resultSupplier.push({
                id: supplier.id,
                name: supplier.name,
                industry: supplier.industry,
                location: supplier.location,
                scope: supplier.scope_name,
                category: supplier.category_name,
                marked_for_deletion: supplier.marked_for_deletion,
                ...resultContacts,
            });
        });
        return resultSupplier;
    };

    const handleImport = async (e) => {
        try {
            return parseXlsxSheets(e.target.files[0], (parsedData) => {
                runImport(parsedData, true, year).then((dryRunResult) => {
                    const dryRunFlat = Object.entries(dryRunResult).reduce(
                        (sum, item) => [...sum, ...item[1]],
                        []
                    );
                    showModal(MODAL_TYPE.CONFIRMATION_IMPORT_SUPPLIERS, {
                        title: `Are you sure you want to import this xlsx?`,
                        content: 'This action is irreversible.',
                        data: dryRunFlat,
                        setImportingXlsx,
                        confirm: async () => {
                            await runImport(parsedData, false, year);
                            notifySuccess('Import successful');
                            setImportingXlsx(false);
                            reloadData();
                        },
                    });
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    // Helper function to get all locations for export
    const getAllLocationsForExport = async () => {
        const locations = await doSwaggerCall('Locations', 'getAllLocations', {
            pageSize: 10000,
            page: 0,
        });
        return locations.locations.map((l) => ({ name: l.name }));
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <>
            <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                spacing={0}
                sx={{ mt: groupby === 'NONE' ? 1 : 0 }}
            >
                <Grid item>
                    <Typography variant="h4">
                        {sectionHeader}
                        {(groupby === 'INDUSTRY' && item.id !== 0) || groupby === 'LOCATION' ? (
                            <IconButton
                                onClick={() => {
                                    groupOpen.filter((idx) => idx !== item.id);
                                    setOpen(!open);
                                }}
                            >
                                <KeyboardArrowUpIcon />
                            </IconButton>
                        ) : null}
                    </Typography>
                </Grid>
                {groupby === 'NONE' ? (
                    <Grid item>
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item>
                                <ButtonGroup color="primary">
                                    <ButtonWithIcon
                                        title="Add supplier"
                                        onClick={() => navigate('/suppliers/new')}
                                        icon={<AddIcon />}
                                    />
                                    <ButtonWithIcon
                                        title="Export sample xlsx"
                                        onClick={async () => {
                                            try {
                                                const allLocations =
                                                    await getAllLocationsForExport();
                                                exportToXlsx(
                                                    sampleData,
                                                    'samplesupplierslist',
                                                    false,
                                                    false,
                                                    true,
                                                    false,
                                                    allLocations
                                                );
                                            } catch (err) {
                                                notifyError(err);
                                            }
                                            notifySuccess('Sample suppliers list exported');
                                        }}
                                        icon={<FileDownloadIcon />}
                                    />
                                    <ButtonWithIcon
                                        title="Export to xlsx"
                                        onClick={async () => {
                                            setGeneratingXlsx(true);
                                            try {
                                                const allLocations =
                                                    await getAllLocationsForExport();
                                                const listToExport = (
                                                    await getSuppliersForOneCompany(year)
                                                ).map((s) => ({
                                                    id: s.id,
                                                    name: s.name,
                                                    industry: s.industry,
                                                    location: s.locationName,
                                                    marked_for_deletion: '',
                                                    contacts: s.contacts,
                                                    category_name: s.categoryName,
                                                    scope_name: s.scopeName,
                                                }));
                                                const listToExportWithContacts =
                                                    getContactsForExport(listToExport);
                                                await exportToXlsx(
                                                    listToExportWithContacts,
                                                    'Suppliers',
                                                    false,
                                                    false,
                                                    true,
                                                    false,
                                                    allLocations
                                                );
                                            } catch (err) {
                                                notifyError(err);
                                            }
                                            setGeneratingXlsx(false);
                                            notifySuccess('Suppliers exported');
                                        }}
                                        icon={
                                            generatingXlsx ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : (
                                                <FileDownloadIcon />
                                            )
                                        }
                                    />
                                    <InputButton
                                        fullWidth
                                        id="supplier-list-upload"
                                        onChange={(e) => {
                                            if (e.target.files.length === 0) {
                                                return;
                                            }
                                            setImportingXlsx(true);
                                            handleImport(e).catch((err) => {
                                                notifyError(err);
                                            });
                                            e.target.value = '';
                                        }}
                                        accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        title="Import from xlsx"
                                        type="file"
                                        loading={importingXlsx}
                                    />
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>
            <SuppliersTable
                rows={data?.suppliers}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                selectMode={selectMode}
                setSelectMode={setSelectMode}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
                groupby={groupby}
                year={year}
                setSuppliersChanged={setSuppliersChanged}
            />
        </>
    );
}

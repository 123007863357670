import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import SortableTableCell from '../../../components/atom/SortableTableCell';

const VarianceTrackerTable = ({ rows, loading, sort, sortDir, setSorting, sameYear }) => {
    const [sortedRows, setSortedRows] = useState(rows);

    const sortRows = () => {
        // Ensure rows is always treated as an array
        const safeRows = Array.isArray(rows) ? rows : [];

        const sortKeyMap = {
            levelType: 'levelType',
            levelName: 'levelName',
            percentage: 'percentage',
            difference: 'difference',
            default: 'id',
        };

        const key = sortKeyMap[sort] || sortKeyMap.default;

        const sortedData = [...safeRows].sort((a, b) => {
            if (a[key] === b[key]) return 0;
            if (sortDir === 'ASC') {
                return a[key] > b[key] ? 1 : -1;
            }
            return a[key] < b[key] ? 1 : -1;
        });

        setSortedRows(sortedData);
    };

    useEffect(() => {
        sortRows();
    }, [rows, sort, sortDir]);

    return (
        <Grid item xs={12}>
            {rows?.length === 0 ? (
                <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Grid item>
                        <Typography variant="subtitle2">
                            {sameYear
                                ? 'The same year is selected for both, please choose a different one.'
                                : 'No data available for the selected year range.'}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <SortableTableCell
                                    arrow={sort === 'levelType' ? sortDir : ''}
                                    onClick={() => setSorting('levelType')}
                                >
                                    Level
                                </SortableTableCell>
                                <SortableTableCell
                                    arrow={sort === 'levelName' ? sortDir : ''}
                                    onClick={() => setSorting('levelName')}
                                >
                                    Name
                                </SortableTableCell>
                                <SortableTableCell
                                    arrow={sort === 'percentage' ? sortDir : ''}
                                    onClick={() => setSorting('percentage')}
                                >
                                    Percent Difference
                                </SortableTableCell>
                                <SortableTableCell
                                    arrow={sort === 'difference' ? sortDir : ''}
                                    onClick={() => setSorting('difference')}
                                >
                                    CO<sub>2</sub>e (mt) Difference
                                </SortableTableCell>
                                <TableCell>YoY Difference</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <SkeletonTable rows={5} cells={5} />
                            ) : (
                                sortedRows?.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.levelType}</TableCell>
                                        <Tooltip title={row.levelHierarchy} placement="left">
                                            <TableCell>{row.levelName}</TableCell>
                                        </Tooltip>
                                        <TableCell>
                                            <p>
                                                {!row.positive && row.percentage && '- '}
                                                {row.percentage ? `${row.percentage} %` : ' '}
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p>
                                                {!row.positive && row.difference && '- '}
                                                {row.difference ? `${row.difference} mt` : ' '}
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p>
                                                {row.year}: {row.sumOfCO2e} mt
                                            </p>
                                            <p>
                                                {row.yearPrev}: {row.sumOfCO2ePrev} mt
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Grid>
    );
};

export default VarianceTrackerTable;

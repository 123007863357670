import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import FileUpload from '@mui/icons-material/FileUpload';
import SearchInput from '../../components/atom/SearchInput';
import useSorting from '../../hooks/useSorting';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import DashboardsTable from './components/DashboardsTable';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import PageWithHeader from '../../components/header/PageWithHeader';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';

const DashboardTemplatesPage = () => {
    const navigate = useNavigate();
    const { showModal } = useModals();
    const { notifyError } = useNotify();
    const [search, setSearch] = useState('');
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [data, loading, error, setData, reloadData] = useApi('Dashboards', 'getDashboards', {
        search,
        limit,
        offset: page * limit,
        sort,
        sortDir,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <PageWithHeader
            title="Dashboards"
            searchInput={
                <SearchInput
                    search={search}
                    setSearch={setSearch}
                    setParams={setParams}
                    limit={limit}
                    fullWidth
                />
            }
            buttons={[
                <ButtonWithIcon
                    title="Add new dashboard"
                    onClick={() => navigate(`/dashboardtemplates/new`)}
                    icon={<AddIcon />}
                />,
                <ButtonWithIcon
                    title="Import from template"
                    onClick={() => {
                        showModal(MODAL_TYPE.IMPORT_DASHBOARD_TEMPLATE, {
                            reloadData,
                        });
                    }}
                    icon={<FileUpload />}
                />,
            ]}
        >
            <DashboardsTable
                rows={data?.dashboards}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </PageWithHeader>
    );
};

export default DashboardTemplatesPage;

import { AccordionSummary, Grid, Accordion, AccordionDetails, InputLabel } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SiteFilter from '../../../../../modals/components/SiteFilter';

const ScenarioSiteFilter = ({ setSiteFilter, companyId }) => {
    return (
        <Grid>
            <Accordion sx={{ border: '1px solid grey' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <InputLabel>Site filter</InputLabel>
                </AccordionSummary>
                <AccordionDetails>
                    <SiteFilter setSiteIdList={setSiteFilter} companyId={companyId} />
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};

export default ScenarioSiteFilter;

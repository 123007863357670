import React, { useCallback, useContext, useState } from 'react';
import ResetPasswordModal from '../modals/ResetPasswordModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import AddEmissionSourceModal from '../modals/AddEmissionSourceModal';
import ConfirmationImportModal from '../modals/ConfirmationImportModal';
import CopyFromAnotherYearModal from '../modals/CopyFromAnotherYearModal';
import ConfirmationImportDatalinesModal from '../modals/ConfirmationImportDatalinesModal';
import ConfirmationImportMasterSourcesModal from '../modals/ConfirmationImportMasterSourcesModal';
import CategoryFilterModal from '../modals/CategoryFilterModal';
import SiteFilterModal from '../modals/SiteFilterModal';
import ConfirmationImportSitesModal from '../modals/ConfirmationImportSitesModal';
import ConfirmationImportLocationsModal from '../modals/ConfirmationImportLocationsModal';
import AssignModal from '../modals/AssignModal';
import IncorrectDatalinesCountModal from '../modals/IncorrectDatalinesCountModal';
import CopyScenarioModal from '../modals/CopyScenarioModal';
import AddConversionLogicTemplateToEmissionSourceModal from '../modals/AddConversionLogicTemplateToEmissionSourceModal';
import AddDashboardPanelModal from '../modals/AddDashboardPanelModal';
import SelectReportModal from '../modals/SelectReportModal';
import ChangeRoleModal from '../modals/ChangeRoleModal';
import DragAndDropPanelModal from '../modals/DragAndDropPanelModal';
import AddLogicTemplateModal from '../modals/AddLogicTemplateModal';
import CopySupplierModal from '../modals/CopySupplierModal';
import ConfirmationImportSuppliersModal from '../modals/ConfirmationImportSuppliersModal';
import ConfirmationSendSurveyModal from '../modals/ConfirmationSendSurveyModal';
import ConfirmationImportUsersModal from '../modals/ConfirmationImportUsersModal';
import ImportDashboardTemplateModal from '../modals/ImportDashboardTemplateModal';
import CopyToAnotherYearModal from '../modals/CopyToAnotherYearModal';
import EmailLogsModal from '../modals/EmailLogsModal';
import AddDatalineWizardModal from '../modals/AddDatalineWizardModal';
import TwoFactorAuthModal from '../modals/TwoFactorAuthModal';
import EsgFilterModal from '../modals/EsgFilterModal';
import GlobalEsgFilterModal from '../modals/GlobalEsgFilterModal';
import BulkEditDatalinesModal from '../modals/BulkEditDatalinesModal';
import ConfirmationImportEsgQuestionsModal from '../modals/ConfirmationImportEsgQuestionsModal';
import AddEsgQuestionModal from '../modals/AddEsgQuestionModal';
import CopyImpModal from '../modals/CopyImpModal';
import AttachDocumentsToDatalinesModal from '../modals/AttachDocumentsToDatalinesModal';
import GlobalEsgFrameworkFilterModal from '../modals/GlobalEsgFrameworkFilterModal';
import AuditCompanyYearModal from '../modals/AuditCompanyYearModal';
import CopyFromAnotherYearEsgModal from '../modals/CopyFromAnotherYearEsgModal';
import CopyToAnotherYearEsgModal from '../modals/CopyToAnotherYearEsgModal';
import SelectEsgReportModal from '../modals/SelectEsgReportModal';
import ConfirmationImportEsgsModal from '../modals/components/esg/ConfirmationImportEsgsModal';
import CopyDatasetModal from '../modals/CopyDatasetModal';
import BulkEditEsgsModal from '../modals/BulkEditEsgsModal';
import ConnectGhgModal from '../modals/ConnectGhgModal';
import AddEsgQuestionToScenarioModal from '../modals/AddEsgQuestionToScenario';
import ConfirmationImpDownloadModal from '../modals/ConfirmationImpDownloadModal';

const ModalContext = React.createContext();

export const MODAL_TYPE = {
    RESET_PASSWORD: 'RESET_PASSWORD',
    CONFIRMATION: 'CONFIRMATION',
    ADD_EMISSION_SOURCE: 'ADD_EMISSION_SOURCE',
    CONFIRMATION_IMPORT: 'CONFIRMATION_IMPORT',
    COPY_FROM_ANOTHER_YEAR: 'COPY_FROM_ANOTHER_YEAR',
    CONFIRMATION_IMPORT_DATALINES: 'CONFIRMATION_IMPORT_DATALINES',
    CONFIRMATION_IMPORT_MASTERSOURCE: 'CONFIRMATION_IMPORT_MASTERSOURCE',
    CATEGORY_FILTER: 'CATEGORY_FILTER',
    SITE_FILTER: 'SITE_FILTER',
    CONFIRMATION_IMPORT_SITES: 'CONFIRMATION_IMPORT_SITES',
    CONFIRMATION_IMPORT_LOCATIONS: 'CONFIRMATION_IMPORT_LOCATIONS',
    ASSIGN_MODAL: 'ASSIGN_MODAL',
    INCORRECT_DATALINES_COUNT: 'INCORRECT_DATALINES_COUNT',
    COPY_SCENARIO: 'COPY_SCENARIO',
    ADD_CONVERSION_LOGIC_TEMPLATE_TO_EMISSION_SOURCE:
        'ADD_CONVERSION_LOGIC_TEMPLATE_TO_EMISSION_SOURCE',
    ADD_DASHBOARD_PANEL: 'ADD_DASHBOARD_PANEL',
    SELECT_REPORT: 'SELECT_REPORT',
    CHANGE_ROLE: 'CHANGE_ROLE',
    DRAG_AND_DROP_PANEL: 'DRAG_AND_DROP_PANEL',
    ADD_LOGIC_TEMPLATE: 'ADD_LOGIC_TEMPLATE',
    COPY_SUPPLIER: 'COPY_SUPPLIER',
    CONFIRMATION_IMPORT_SUPPLIERS: 'CONFIRMATION_IMPORT_SUPPLIERS',
    CONFIRMATION_SEND_SURVEY: 'CONFIRMATION_SEND_SURVEY',
    CONFIRMATION_IMPORT_USERS: 'CONFIRMATION_IMPORT_USERS',
    IMPORT_DASHBOARD_TEMPLATE: 'IMPORT_DASHBOARD_TEMPLATE',
    COPY_TO_ANOTHER_YEAR: 'COPY_TO_ANOTHER_YEAR',
    EMAIL_LOGS: 'EMAIL_LOGS',
    COPY_DATASET: 'COPY_DATASET',
    ADD_DATALINE_WIZARD: 'ADD_DATALINE_WIZARD',
    TWO_FACTOR_AUTH: 'TWO_FACTOR_AUTH',
    ESG_FILTER: 'ESG_FILTER',
    ESG_GLOBAL_FILTER: 'ESG_GLOBAL_FILTER',
    SELECT_ESG_REPORT: 'SELECT_ESG_REPORT',
    BULK_EDIT_DATALINES: 'BULK_EDIT_DATALINES',
    CONFIRMATION_IMPORT_ESG_QUESTIONS: 'CONFIRMATION_IMPORT_ESG_QUESTIONS',
    ADD_ESG_QUESTION: 'ADD_ESG_QUESTION',
    COPY_IMP: 'COPY_IMP',
    ATTACH_DOCUMENTS_TO_DATALINES: 'ATTACH_DOCUMENTS_TO_DATALINES',
    ESG_FRAMEWORK_GLOBAL_FILTER: 'ESG_FRAMEWORK_GLOBAL_FILTER',
    AUDIT_COMPANY_YEAR: 'AUDIT_COMPANY_YEAR',
    COPY_ESG_FROM_ANOTHER_YEAR: 'COPY_ESG_FROM_ANOTHER_YEAR',
    COPY_ESG_TO_ANOTHER_YEAR: 'COPY_ESG_TO_ANOTHER_YEAR',
    CONFIRMATION_IMPORT_ESGS: 'CONFIRMATION_IMPORT_ESGS',
    BULK_EDIT_ESGS: 'BULK_EDIT_ESGS',
    CONNECT_GHG: 'CONNECT_GHG',
    ADD_ESG_QUESTION_TO_SCENARIO: 'ADD_ESG_QUESTION_TO_SCENARIO',
    CONFIRMATION_IMP_DOWNLOAD: 'CONFIRMATION_IMP_DOWNLOAD',
    NONE: false,
};

export function Modals() {
    return (
        <ModalContext.Consumer>
            {(context) => {
                const allProps = {
                    ...context.props,
                    currentlyShowingModal: context.currentlyShowingModal,
                    showModal: context.showModal,
                    MODAL_TYPE,
                };
                return (
                    <div>
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.CONFIRMATION_IMPORT_MASTERSOURCE && (
                            <ConfirmationImportMasterSourcesModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.RESET_PASSWORD && (
                            <ResetPasswordModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CONFIRMATION && (
                            <ConfirmationModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.ADD_EMISSION_SOURCE && (
                            <AddEmissionSourceModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CONFIRMATION_IMPORT && (
                            <ConfirmationImportModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.COPY_FROM_ANOTHER_YEAR && (
                            <CopyFromAnotherYearModal {...allProps} />
                        )}
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.CONFIRMATION_IMPORT_DATALINES && (
                            <ConfirmationImportDatalinesModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CATEGORY_FILTER && (
                            <CategoryFilterModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.SITE_FILTER && (
                            <SiteFilterModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CONFIRMATION_IMPORT_SITES && (
                            <ConfirmationImportSitesModal {...allProps} />
                        )}
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.CONFIRMATION_IMPORT_LOCATIONS && (
                            <ConfirmationImportLocationsModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.ASSIGN_MODAL && (
                            <AssignModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.INCORRECT_DATALINES_COUNT && (
                            <IncorrectDatalinesCountModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.COPY_SCENARIO && (
                            <CopyScenarioModal {...allProps} />
                        )}
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.ADD_CONVERSION_LOGIC_TEMPLATE_TO_EMISSION_SOURCE && (
                            <AddConversionLogicTemplateToEmissionSourceModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.ADD_DASHBOARD_PANEL && (
                            <AddDashboardPanelModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.SELECT_REPORT && (
                            <SelectReportModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CHANGE_ROLE && (
                            <ChangeRoleModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.DRAG_AND_DROP_PANEL && (
                            <DragAndDropPanelModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.ADD_LOGIC_TEMPLATE && (
                            <AddLogicTemplateModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.COPY_SUPPLIER && (
                            <CopySupplierModal {...allProps} />
                        )}
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.CONFIRMATION_IMPORT_SUPPLIERS && (
                            <ConfirmationImportSuppliersModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CONFIRMATION_SEND_SURVEY && (
                            <ConfirmationSendSurveyModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CONFIRMATION_IMPORT_USERS && (
                            <ConfirmationImportUsersModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.IMPORT_DASHBOARD_TEMPLATE && (
                            <ImportDashboardTemplateModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.COPY_TO_ANOTHER_YEAR && (
                            <CopyToAnotherYearModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.EMAIL_LOGS && (
                            <EmailLogsModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.COPY_DATASET && (
                            <CopyDatasetModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.ADD_DATALINE_WIZARD && (
                            <AddDatalineWizardModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.TWO_FACTOR_AUTH && (
                            <TwoFactorAuthModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.ESG_FILTER && (
                            <EsgFilterModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.SELECT_ESG_REPORT && (
                            <SelectEsgReportModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.ESG_GLOBAL_FILTER && (
                            <GlobalEsgFilterModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.BULK_EDIT_DATALINES && (
                            <BulkEditDatalinesModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.ADD_ESG_QUESTION && (
                            <AddEsgQuestionModal {...allProps} />
                        )}
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.CONFIRMATION_IMPORT_ESG_QUESTIONS && (
                            <ConfirmationImportEsgQuestionsModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.COPY_IMP && (
                            <CopyImpModal {...allProps} />
                        )}
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.ATTACH_DOCUMENTS_TO_DATALINES && (
                            <AttachDocumentsToDatalinesModal {...allProps} />
                        )}
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.ESG_FRAMEWORK_GLOBAL_FILTER && (
                            <GlobalEsgFrameworkFilterModal {...allProps} />
                        )}
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.COPY_ESG_FROM_ANOTHER_YEAR && (
                            <CopyFromAnotherYearEsgModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.COPY_ESG_TO_ANOTHER_YEAR && (
                            <CopyToAnotherYearEsgModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.AUDIT_COMPANY_YEAR && (
                            <AuditCompanyYearModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CONFIRMATION_IMPORT_ESGS && (
                            <ConfirmationImportEsgsModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.BULK_EDIT_ESGS && (
                            <BulkEditEsgsModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CONNECT_GHG && (
                            <ConnectGhgModal {...allProps} />
                        )}
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.ADD_ESG_QUESTION_TO_SCENARIO && (
                            <AddEsgQuestionToScenarioModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CONFIRMATION_IMP_DOWNLOAD && (
                            <ConfirmationImpDownloadModal {...allProps} />
                        )}
                    </div>
                );
            }}
        </ModalContext.Consumer>
    );
}

export function ModalContextProvider({ children }) {
    const [modalState, setModalState] = useState({
        currentlyShowingModal: false,
        props: {},
    });

    const showModal = useCallback(
        (currentlyShowingModal, newprops = {}) => {
            setModalState({
                currentlyShowingModal,
                props: newprops,
            });
        },
        [setModalState]
    );

    return (
        <ModalContext.Provider value={{ ...modalState, showModal }}>
            {children}
            <Modals />
        </ModalContext.Provider>
    );
}

ModalContext.displayName = 'ModalContext';

export function useModals() {
    return useContext(ModalContext);
}

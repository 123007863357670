import { Typography, CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import useSorting from '../../hooks/useSorting';
import VarianceTrackerTable from './components/VarianceTrackerTable';
import SearchInput from '../../components/atom/SearchInput';
import YearRangeSelector from './components/YearRangeSelector';
import { exportToXlsx } from '../../helpers/excelExportHelpers';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import PageWithHeader from '../../components/header/PageWithHeader';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';

const VarianceTrackerPage = () => {
    const { companyId } = useParams();
    const { notifyError, notifySuccess } = useNotify();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const { availableYears } = useGlobalFilters();
    const [fromYear, setFromYear] = useState(availableYears[0]?.year || 2023);
    const [toYear, setToYear] = useState(availableYears[1]?.year || 2024);
    const [search, setSearch] = useState('');
    const [generatingXlsx, setGeneratingXlsx] = useState(false);

    const [data, loading, error, setData, reloadData] = useApi('Categories', 'getVarianceTracker', {
        id: companyId,
        years: [fromYear, toYear],
        search,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <PageWithHeader
            title="Variance Tracker"
            subtitle={
                <Typography variant="caption">
                    Flag limit by company: <b>{data?.flagLimit}</b> %
                </Typography>
            }
            searchInput={
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        height: '100%',
                    }}
                >
                    <SearchInput fullWidth search={search} setSearch={setSearch} />
                </Grid>
            }
            filters={[
                <YearRangeSelector
                    fromYear={fromYear}
                    setFromYear={setFromYear}
                    toYear={toYear}
                    setToYear={setToYear}
                />,
            ]}
            buttons={
                data?.varianceTrackerData?.length > 0 && (
                    <ButtonWithIcon
                        title="Export to xlsx"
                        onClick={async () => {
                            setGeneratingXlsx(true);
                            try {
                                const listToExport = {
                                    headerYears: {
                                        fromYear: data?.varianceTrackerData[0]?.year,
                                        toYear: data?.varianceTrackerData[0]?.yearPrev,
                                    },
                                    rows: data?.varianceTrackerData?.map((s) => ({
                                        levelType: s.levelType,
                                        levelHierarchy: s.levelHierarchy,
                                        levelName: s.levelName,
                                        percentDifference: `${
                                            !s.positive && s.percentage ? '- ' : ''
                                        }${s.percentage ? `${s.percentage} %` : ''}`,
                                        co2eDifference: `${
                                            !s.positive && s.difference ? '- ' : ''
                                        }${s.difference ? `${s.difference} mt` : ''}`,
                                        fromYear: `${s.sumOfCO2e}`,
                                        toYear: `${s.sumOfCO2ePrev}`,
                                    })),
                                };

                                // TODO: false, false, true, false... it's a mess, should refact
                                await exportToXlsx(
                                    listToExport,
                                    'Variance Tracker',
                                    false,
                                    false,
                                    false,
                                    false,
                                    null,
                                    false,
                                    true
                                );
                            } catch (err) {
                                notifyError(err);
                                setGeneratingXlsx(false);
                            }
                            setGeneratingXlsx(false);
                            notifySuccess('Variance Tracker exported');
                        }}
                        icon={
                            generatingXlsx ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <FileDownloadIcon />
                            )
                        }
                    />
                )
            }
        >
            <VarianceTrackerTable
                rows={data?.varianceTrackerData}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
                sameYear={fromYear === toYear}
            />
        </PageWithHeader>
    );
};

export default VarianceTrackerPage;

import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import CommonButton from '../../../components/atom/CommonButton';
import PageWithHeader from '../../../components/header/PageWithHeader';

const defaultLocation = {
    name: 'New Location',
};

function EditLocationPage({ newLocation }) {
    const { parentId, locationId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [location, setLocation] = useState(null);
    const { notifyError, notifySuccess } = useNotify();

    const loadLocation = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Locations', 'getLocation', { id: locationId })
            .then((res) => {
                setLocation({
                    id: res.id,
                    name: res.name,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate(`/locations/${parentId}`);
            });
    }, []);

    useEffect(() => {
        if (!newLocation) {
            loadLocation();
        } else {
            setLoading(false);
        }
    }, [parentId]);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            if (newLocation) {
                await doSwaggerCall(
                    'Locations',
                    'addLocation',
                    { id: locationId },
                    {
                        name: values.name,
                        parentId: parseInt(parentId, 10),
                    }
                );
                navigate(`/locations/${parentId}`);
                notifySuccess('Location added');
            } else {
                await doSwaggerCall(
                    'Locations',
                    'editLocation',
                    { id: locationId },
                    {
                        name: values.name,
                    }
                );
                loadLocation();
                setSubmitting(false);
                navigate(`/locations/${parentId}`);
                notifySuccess('Location updated');
            }
        } catch (err) {
            notifyError(err);
            setSubmitting(false);
        }
    };

    if (loading) {
        return true;
    }

    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
    });

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={location || { ...defaultLocation }}
            validationSchema={validateSchema}
        >
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <PageWithHeader title={newLocation ? 'Add new Location' : 'Edit Location'}>
                        <Grid item xs={6}>
                            <BlockWithTitle title="Location">
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        label="Name"
                                        value={values.name}
                                        onChange={(ev) => setFieldValue('name', ev.target.value)}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name ? errors.name : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CommonButton
                                        fullWidth
                                        onClick={() => navigate(`/locations/${parentId}`)}
                                        label="Cancel"
                                        buttonType="secondary"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CommonButton
                                        fullWidth
                                        disabled={isSubmitting}
                                        type="submit"
                                        label="Save"
                                        buttonType="primary"
                                    />
                                </Grid>
                            </BlockWithTitle>
                        </Grid>
                    </PageWithHeader>
                </Form>
            )}
        </Formik>
    );
}

export default EditLocationPage;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import useNotify from '../../../hooks/useNotify';
import { TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constans';
import useSorting from '../../../hooks/useSorting';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import EsgHierarchyTable from '../components/EsgHierarchyTable';
import EsgEditorSwitcher from '../components/EsgEditorSwitcher';
import SearchInput from '../../../components/atom/SearchInput';
import PageWithHeader from '../../../components/header/PageWithHeader';
import ButtonWithIcon from '../../../components/header/ButtonWithIcon';

const EsgFrameworksPage = () => {
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotify();
    const [search, setSearch] = useState('');
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [data, loading, error, setData, reloadData] = useApi(
        'EsgFrameworks',
        'getEsgFrameworks',
        {
            search,
            limit,
            offset: page * limit,
            sort,
            sortDir,
        }
    );

    const deleteFramework = (id) => {
        doSwaggerCall('EsgFrameworks', 'deleteEsgFramework', { id })
            .then(() => {
                notifySuccess('ESG framework deleted');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <>
            <EsgEditorSwitcher />
            <PageWithHeader
                title="ESG Frameworks"
                buttons={
                    <ButtonWithIcon
                        title="Add new ESG framework"
                        onClick={() => navigate('/esgframeworks/new')}
                        icon={<AddIcon />}
                    />
                }
                searchInput={
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        setParams={setParams}
                        limit={limit}
                        fullWidth
                    />
                }
            >
                <EsgHierarchyTable
                    title="ESG Frameworks"
                    rows={data?.esgFrameworks}
                    limit={limit}
                    page={page}
                    setParams={setParams}
                    total={data?.total}
                    loading={loading}
                    sort={sort}
                    sortDir={sortDir}
                    setSorting={setSorting}
                    deleteRow={deleteFramework}
                />
            </PageWithHeader>
        </>
    );
};

export default EsgFrameworksPage;

import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import useSorting from '../../hooks/useSorting';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import SearchInput from '../../components/atom/SearchInput';
import AirportsTable from './components/AirportsTable';
import useDebounce from '../../hooks/useDebounce';
import PageWithHeader from '../../components/header/PageWithHeader';

const AirportsPage = () => {
    const { notifyError } = useNotify();
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'DESC']);
    const [search, setSearch] = useState('');
    const [inputValue, setInputValue] = useState(search);
    const debouncedSearch = useDebounce(inputValue, 500);
    const [data, loading, error] = useApi('AirportDistances', 'getAirportList', {
        limit,
        offset: page * limit,
        sort,
        sortDir,
        search,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    useEffect(() => {
        if (debouncedSearch !== search) {
            if (setParams) {
                setParams({ page: 0, limit });
            }
            setSearch(debouncedSearch);
        }
    }, [debouncedSearch, search, setSearch, setParams, limit]);

    return (
        <PageWithHeader
            title="Airports"
            filters={[
                <SearchInput
                    search={inputValue}
                    setSearch={setInputValue}
                    setParams={setParams}
                    limit={limit}
                    fullWidth
                />,
            ]}
        >
            <AirportsTable
                rows={data?.airports}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </PageWithHeader>
    );
};

export default AirportsPage;

import { Form, Formik } from 'formik';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
    Grid,
    TextField,
    InputLabel,
    InputAdornment,
    Alert,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { KeyboardArrowLeft } from '@mui/icons-material';
import AddItemButton from '../../components/atom/AddItemButton';
import { doSwaggerCall } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { PERMISSIONS } from '../../helpers/constans';
import AutocompleteSelect from '../../components/atom/AutoCompleteSelect';
import { siteAutocomplete, userAutocomplete } from '../../autocomplete';
import DatalineFormulaPickerDropdown from '../../pages/datalineedit/components/DatalineFormulaPickerDropdown';
import FloatInput from '../../components/atom/FloatInput';
import FreeSoloDatalineTagAutoComplete from '../../components/atom/FreeSoloDatalineTagAutoComplete';
import DocumentField from '../../pages/datalineedit/components/DocumentField';
import HelperIcon from '../../components/atom/HelperIcon';
import validateDataline from '../../pages/datalineedit/helpers/validateDataline';
import CommonButton from '../../components/atom/CommonButton';
import checkAirportCalculatorVisibility from '../../helpers/checkAirportCalculatorVisibility';
import DateRangeAlert from '../../components/atom/DateRangeAlert';

function AddDatalineForm({
    year,
    emissionSourceId,
    addDataline,
    percentages,
    setPercentages,
    setFilterStep,
    scopeName,
    categoryName,
    subcategoryName,
    emissionSourceName,
}) {
    const [dataline, setDataline] = useState({
        company_id: 1,
        year,
        emission_source_id: emissionSourceId,
        emission_source: '',
        emission_description: '',
        calculation_type: null,
        site_id: null,
        co2e: 0,
        fields: {},
        amount: '',
        notes: null,
        is_estimated: false,
        assigned_user: null,
        owner_user: null,
        documents: null,
        datalineTags: [],
        from_date: null,
        to_date: null,
        cost: null,
        system_managed: false,
        system_managed_emission_source: false,
    });
    const [showNotes, setShowNotes] = useState(!(dataline.notes === null || dataline.notes === ''));
    const [showDocuments, setShowDocuments] = useState(dataline?.documents?.length > 0);
    const [showAssign, setShowAssign] = useState(!!dataline?.assigned_user?.user_id);
    const [showOwner, setShowOwner] = useState(!!dataline?.owner_user?.user_id);
    const [showDatalineTags, setShowDatalineTags] = useState(dataline?.datalineTags.length > 0);
    const [defaultField, setDefaultField] = useState(null);
    const [showDate, setShowDate] = useState(!!dataline?.to_date || !!dataline?.from_date);
    const [showCost, setShowCost] = useState(!!dataline?.cost);
    const [loading, setLoading] = useState(true);
    const [formError, setFormError] = useState({});
    const [disabledByAirportCalculator, setDisabledByAirportCalculator] = useState(false);
    const [usedDateRange, setUsedDateRange] = useState(false);

    const { userPermissions } = useAuth();
    const basicModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.BASIC_MODIFY_DATALINES
    );
    const complexModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.COMPLEX_MODIFY_DATALINES
    );
    const systemManagedEmissionSource = dataline?.system_managed_emission_source;

    useEffect(() => {
        // emissionSourceId from grouping when source filter is not selected
        if (!emissionSourceId) {
            setLoading(false);
            return;
        }
        setLoading(true);
        doSwaggerCall('EmissionSources', 'getEmissionSource', {
            id: emissionSourceId,
        })
            .then((s) => {
                setDataline((oldDataline) => {
                    // if the "old dataline" value has anything, no need to override everything
                    if (oldDataline.emission_source !== '') {
                        return oldDataline;
                    }
                    return {
                        ...oldDataline,
                        emission_source_id: emissionSourceId,
                        emission_source: s,
                        emission_description: s.name,
                        calculation_type: s.default_calculation_logic,
                        incorrect: s.base_unit === '',
                    };
                });
                setLoading(false);
            })
            .catch(console.log);
    }, [emissionSourceId, setLoading, setDataline]);

    // set site default_field
    useEffect(() => {
        setDefaultField(dataline?.site_default_field);
    }, [dataline]);

    const validate = (values) => {
        return validateDataline(values);
    };

    if (loading) {
        return true;
    }

    return (
        <Grid item>
            <Formik
                onSubmit={addDataline}
                enableReinitialize
                initialValues={dataline}
                validate={validate}
            >
                {({ isSubmitting, values, setFieldValue, touched, errors, validateForm }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item container spacing={2}>
                                <Grid item sx={{ textAlign: 'center' }}>
                                    <Typography variant="h4" style={{ fontSize: '16px' }}>
                                        {`${scopeName} - ${categoryName} - ${subcategoryName} - ${emissionSourceName}`}
                                    </Typography>
                                </Grid>
                                <Grid item container justifyContent="space-between">
                                    <Grid item>
                                        <Grid item sx={{ pb: 1 }}>
                                            <Typography variant="h4" style={{ fontSize: '14px' }}>
                                                Add new Dataline
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                {values.emission_source.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {dataline.emission_source.guide_note && (
                                        <Grid item>
                                            <HelperIcon
                                                title={dataline.emission_source.name}
                                                guideNote={dataline.emission_source.guide_note}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        autoComplete="off"
                                        label="Emission description"
                                        value={values.emission_description}
                                        onChange={(ev) => {
                                            setFieldValue('emission_description', ev.target.value);
                                            setUsedDateRange(false); // reset alert if user changes the description
                                        }}
                                        InputProps={{
                                            startAdornment: values.incorrect === true && (
                                                <WarningIcon
                                                    sx={{ mr: 0.5 }}
                                                    style={{ color: 'red' }}
                                                />
                                            ),
                                        }}
                                        disabled={
                                            (!basicModifyDatalinePermission &&
                                                !complexModifyDatalinePermission) ||
                                            systemManagedEmissionSource
                                        }
                                    />
                                </Grid>
                                {!usedDateRange &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !systemManagedEmissionSource && (
                                        <DateRangeAlert
                                            values={values}
                                            setShowDate={setShowDate}
                                            year={year}
                                            setFieldValue={setFieldValue}
                                            setUsedDateRange={setUsedDateRange}
                                        />
                                    )}
                            </Grid>
                            <Grid item container spacing={2}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!values.is_estimated}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'is_estimated',
                                                        !!e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="Estimated value"
                                        labelPlacement="end"
                                        disabled={
                                            (!basicModifyDatalinePermission &&
                                                !complexModifyDatalinePermission) ||
                                            systemManagedEmissionSource
                                        }
                                    />
                                </Grid>
                                <Grid item style={{ flex: '1 1 0' }}>
                                    <AutocompleteSelect
                                        size="small"
                                        initCall={siteAutocomplete.init}
                                        autocompleteCall={siteAutocomplete.search}
                                        value={values?.site_id}
                                        setValue={(ev, getItem) => {
                                            setDefaultField(getItem(ev));
                                            setFieldValue('site_id', ev);
                                        }}
                                        disabled={
                                            (!basicModifyDatalinePermission &&
                                                !complexModifyDatalinePermission) ||
                                            systemManagedEmissionSource
                                        }
                                    >
                                        <TextField label="Site" required />
                                    </AutocompleteSelect>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <DatalineFormulaPickerDropdown
                                    noBaseUnit={values?.emission_source?.has_children}
                                    conversionLogic={values?.emission_source?.unit_conversion_logic}
                                    calculationType={values?.calculation_type}
                                    setCalculationType={(newCalcType) => {
                                        setDisabledByAirportCalculator(false);
                                        setFieldValue('calculation_type', newCalcType);
                                    }}
                                    fields={values.fields}
                                    setFieldValue={setFieldValue}
                                    baseUnit={values?.emission_source?.base_unit}
                                    amount={values.amount}
                                    defaultField={defaultField}
                                    setAmount={(amount) => {
                                        setFieldValue('amount', amount);
                                    }}
                                    systemManagedDataline={false}
                                    systemManagedEmissionSource={systemManagedEmissionSource}
                                    closedYear={false}
                                    isAirportCalculatorVisible={checkAirportCalculatorVisibility(
                                        scopeName,
                                        categoryName,
                                        subcategoryName,
                                        emissionSourceName
                                    )}
                                    setShowNotes={setShowNotes}
                                    setDisabledByAirportCalculator={setDisabledByAirportCalculator}
                                />
                            </Grid>
                            {showNotes && (
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        multiline
                                        name="notes"
                                        label="Notes (optional, incl. in Audit Report)"
                                        value={values.notes || ''}
                                        onChange={(ev) => setFieldValue('notes', ev.target.value)}
                                        disabled={
                                            (!basicModifyDatalinePermission &&
                                                !complexModifyDatalinePermission) ||
                                            systemManagedEmissionSource
                                        }
                                    />
                                </Grid>
                            )}
                            {showCost && (
                                <Grid item xs={6}>
                                    <FloatInput
                                        fullWidth
                                        autoComplete="off"
                                        label="Cost (optional)"
                                        type="number"
                                        value={values.cost}
                                        onChange={(ev) => setFieldValue('cost', ev.target.rawValue)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">$</InputAdornment>
                                            ),
                                        }}
                                        disabled={
                                            (!basicModifyDatalinePermission &&
                                                !complexModifyDatalinePermission) ||
                                            systemManagedEmissionSource
                                        }
                                    />
                                </Grid>
                            )}
                            {showAssign && (
                                <Grid item xs={6}>
                                    <AutocompleteSelect
                                        initCall={userAutocomplete.init}
                                        autocompleteCall={userAutocomplete.search}
                                        value={values.assigned_user?.user_id}
                                        setValue={(ev) => {
                                            setFieldValue('assigned_user.user_id', ev);
                                        }}
                                        disabled={
                                            !complexModifyDatalinePermission ||
                                            systemManagedEmissionSource
                                        }
                                    >
                                        <TextField label="Assigned user" />
                                    </AutocompleteSelect>
                                </Grid>
                            )}
                            {showOwner && (
                                <Grid item xs={6}>
                                    <AutocompleteSelect
                                        initCall={userAutocomplete.init}
                                        autocompleteCall={userAutocomplete.search}
                                        value={values?.owner_user?.user_id}
                                        setValue={(ev) => {
                                            setFieldValue('owner_user.user_id', ev);
                                        }}
                                        disabled={
                                            !complexModifyDatalinePermission ||
                                            systemManagedEmissionSource
                                        }
                                    >
                                        <TextField label="Owner" />
                                    </AutocompleteSelect>
                                </Grid>
                            )}
                            {showDate && (
                                <>
                                    <Grid item xs={6}>
                                        <InputLabel>From date</InputLabel>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            id="from_date"
                                            name="from_date"
                                            value={values.from_date}
                                            onChange={(ev) =>
                                                setFieldValue('from_date', ev.target.value)
                                            }
                                            error={touched.from_date && Boolean(errors.from_date)}
                                            helperText={touched.from_date ? errors.from_date : ''}
                                            disabled={
                                                (!basicModifyDatalinePermission &&
                                                    !complexModifyDatalinePermission) ||
                                                systemManagedEmissionSource
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel>To date</InputLabel>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            id="to_date"
                                            name="to_date"
                                            value={values.to_date}
                                            onChange={(ev) =>
                                                setFieldValue('to_date', ev.target.value)
                                            }
                                            error={touched.to_date && Boolean(errors.to_date)}
                                            helperText={touched.to_date ? errors.to_date : ''}
                                            disabled={
                                                (!basicModifyDatalinePermission &&
                                                    !complexModifyDatalinePermission) ||
                                                systemManagedEmissionSource
                                            }
                                        />
                                    </Grid>
                                </>
                            )}
                            {showDocuments && (
                                <DocumentField
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    basicModifyDatalinePermission={basicModifyDatalinePermission}
                                    complexModifyDatalinePermission={
                                        complexModifyDatalinePermission
                                    }
                                    systemManagedEmissionSource={systemManagedEmissionSource}
                                    closedYear={false}
                                />
                            )}
                            {showDatalineTags && (
                                <FreeSoloDatalineTagAutoComplete
                                    fullWidth
                                    label="Tags"
                                    value={values?.datalineTags}
                                    setValue={(newValue) => setFieldValue('datalineTags', newValue)}
                                    percentages={percentages}
                                    setPercentages={setPercentages}
                                    basicModifyDatalinePermission={basicModifyDatalinePermission}
                                    complexModifyDatalinePermission={
                                        complexModifyDatalinePermission
                                    }
                                    systemManagedDataline={false}
                                    systemManagedEmissionSource={systemManagedEmissionSource}
                                    closedYear={false}
                                >
                                    <TextField label="Dataline tags" required />
                                </FreeSoloDatalineTagAutoComplete>
                            )}
                            {(formError?.from_date || formError?.to_date) && (
                                <Grid item xs={12}>
                                    <Alert severity="error" sx={{ textAlign: 'center' }}>
                                        <div>{Object.values(formError)}</div>
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {!showNotes &&
                                        (basicModifyDatalinePermission ||
                                            complexModifyDatalinePermission) &&
                                        !systemManagedEmissionSource && (
                                            <AddItemButton
                                                label="Note"
                                                onClick={() => setShowNotes(true)}
                                            />
                                        )}
                                    {!showDocuments &&
                                        (basicModifyDatalinePermission ||
                                            complexModifyDatalinePermission) &&
                                        !systemManagedEmissionSource && (
                                            <AddItemButton
                                                label="Document"
                                                onClick={() => setShowDocuments(true)}
                                            />
                                        )}
                                    {!showAssign &&
                                        complexModifyDatalinePermission &&
                                        !systemManagedEmissionSource && (
                                            <AddItemButton
                                                label="Assign"
                                                onClick={() => setShowAssign(true)}
                                            />
                                        )}
                                    {!showOwner &&
                                        complexModifyDatalinePermission &&
                                        !systemManagedEmissionSource && (
                                            <AddItemButton
                                                label="Owner"
                                                onClick={() => setShowOwner(true)}
                                            />
                                        )}
                                    {!showDate &&
                                        (basicModifyDatalinePermission ||
                                            complexModifyDatalinePermission) &&
                                        !systemManagedEmissionSource && (
                                            <AddItemButton
                                                label="Dates (from-to)"
                                                onClick={() => setShowDate(true)}
                                            />
                                        )}
                                    {!showCost &&
                                        (basicModifyDatalinePermission ||
                                            complexModifyDatalinePermission) &&
                                        !systemManagedEmissionSource && (
                                            <AddItemButton
                                                label="Cost"
                                                onClick={() => setShowCost(true)}
                                            />
                                        )}
                                    {!showDatalineTags &&
                                        (basicModifyDatalinePermission ||
                                            complexModifyDatalinePermission) &&
                                        !systemManagedEmissionSource && (
                                            <AddItemButton
                                                label="Dataline tags"
                                                onClick={() => setShowDatalineTags(true)}
                                            />
                                        )}
                                </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ pt: 2, justifyContent: 'space-between' }}
                                >
                                    <Grid item xs={2}>
                                        <Button
                                            variant="text"
                                            fullWidth
                                            onClick={() => {
                                                setFilterStep(true);
                                            }}
                                        >
                                            <KeyboardArrowLeft />
                                        </Button>
                                    </Grid>
                                    {!systemManagedEmissionSource && (
                                        <Grid item xs={3}>
                                            <CommonButton
                                                // hack to validate form before submit
                                                onClick={(e) => {
                                                    validateForm()
                                                        .then((error) => {
                                                            if (
                                                                e.target
                                                                    .closest('form')
                                                                    .checkValidity() &&
                                                                !error.from_date &&
                                                                !error.to_date
                                                            ) {
                                                                addDataline(values, true);
                                                            } else {
                                                                e.target
                                                                    .closest('form')
                                                                    .reportValidity();
                                                                // show error
                                                                setFormError(error);
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            e.target
                                                                .closest('form')
                                                                .reportValidity();
                                                            // show error
                                                            setFormError(error);
                                                        });
                                                }}
                                                fullWidth
                                                label="Save and jump to Dataline"
                                                buttonType="primary"
                                                disabled={
                                                    isSubmitting || disabledByAirportCalculator
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
}

export default AddDatalineForm;
